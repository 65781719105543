import ConditionalWrapper from '@payaca/components/conditionalWrapper/ConditionalWrapper';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Modal from '@payaca/components/plModal/Modal';
import ValidatedForm, {
  TFieldValidators,
  TOnFormSubmit,
  TRenderFormContents,
} from '@payaca/components/validatedForm/ValidatedForm';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { FC, useCallback, useMemo } from 'react';

type TFormState = {
  name: string;
};

export interface IProps {
  customerName: string;
  isInModal?: boolean;
  onCancel?: () => void;
  onSubmit?: TOnFormSubmit<TFormState>;
}

const UpdateCustomerControl: FC<IProps> = (props) => {
  const { customerName, isInModal = false, onCancel, onSubmit } = props;

  const initialFormState = useMemo<TFormState>(() => {
    return {
      name: customerName,
    };
  }, [customerName]);

  const fieldValidators = useMemo<TFieldValidators<TFormState>>(() => {
    return {
      name: [getIsRequiredFieldValidator()],
    };
  }, []);

  const renderFormContents = useCallback<TRenderFormContents<TFormState>>(
    (
      isValid,
      formState,
      validationState,
      touchedState,
      onFieldChange,
      onFieldTouch
    ) => {
      return (
        <>
          <ConditionalWrapper condition={isInModal} Wrapper={Modal.Body}>
            <Field.Legacy
              name="name"
              validationState={
                validationState?.['name']?.isValid === false &&
                touchedState?.['name']
                  ? {
                      isValid: false,
                      validationMessages: validationState?.['name']?.errors,
                    }
                  : undefined
              }
            >
              <Field.Label>Customer Name</Field.Label>

              <Input
                value={formState.name}
                onChange={(value) => {
                  onFieldChange({ name: value });
                }}
                onBlur={() => onFieldTouch('name')}
              />
            </Field.Legacy>
          </ConditionalWrapper>

          <ConditionalWrapper condition={isInModal} Wrapper={Modal.Footer}>
            <ConditionalWrapper
              condition={isInModal}
              Wrapper={Modal.Footer.Actions}
            >
              <Button
                type="button"
                variant={EBtnVariant.Outline}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={!isValid}>
                Save
              </Button>
            </ConditionalWrapper>
          </ConditionalWrapper>
        </>
      );
    },
    [isInModal, onCancel]
  );

  return (
    <ValidatedForm<TFormState>
      initialFormState={initialFormState}
      fieldValidators={fieldValidators}
      renderFormContents={renderFormContents}
      onFormSubmit={onSubmit}
    />
  );
};

export default UpdateCustomerControl;
