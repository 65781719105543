import useGetExistingCustomerContactEmails from '@/api/queries/customers/useGetExistingCustomerContactEmails';
import ConditionalWrapper from '@payaca/components/conditionalWrapper/ConditionalWrapper';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Modal from '@payaca/components/plModal/Modal';
import ValidatedForm, {
  TFieldValidators,
  TOnFormSubmit,
  TRenderFormContents,
} from '@payaca/components/validatedForm/ValidatedForm';
import {
  allowEmptyCustomerContactPhoneNumberFieldValidator,
  customerContactDescriptionFieldValidator,
  getCustomerContactEmailFieldValidator,
} from '@payaca/helpers/customerValidationHelper';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { Nullish } from '@payaca/utilities/types';
import { FC, useCallback, useMemo } from 'react';

type TFormState = {
  id?: Nullish<string>;
  name?: Nullish<string>;
  description?: Nullish<string>;
  email?: Nullish<string>;
  phone?: Nullish<string>;
};

export interface IProps {
  isInModal?: boolean;
  contact?: TFormState;
  // Memoize!
  onSave?: (contact: {
    id?: Nullish<string>;
    name: string;
    description?: Nullish<string>;
    email?: Nullish<string>;
    phone?: Nullish<string>;
  }) => void;
  onClose?: () => void;
}

const AddEditContactControl: FC<IProps> = (props) => {
  const { isInModal = false, onSave, onClose, contact } = props;

  const { data: existingCustomerContactEmails, isLoading } =
    useGetExistingCustomerContactEmails();

  const fieldValidators = useMemo<TFieldValidators<TFormState>>(() => {
    const disallowedEmails =
      existingCustomerContactEmails?.filter(
        (email) => email != contact?.email
      ) || [];

    return {
      name: [getIsRequiredFieldValidator()],
      description: [customerContactDescriptionFieldValidator],
      email: [
        getCustomerContactEmailFieldValidator({
          existingCustomerContactEmails: disallowedEmails,
        }),
      ],
      phone: [allowEmptyCustomerContactPhoneNumberFieldValidator],
    };
  }, [existingCustomerContactEmails, contact?.email]);

  const initialFormState = useMemo<TFormState>(() => {
    return {
      id: contact?.id || undefined,
      name: contact?.name || undefined,
      description: contact?.description || undefined,
      email: contact?.email || undefined,
      phone: contact?.phone || undefined,
    };
  }, [contact]);

  const renderFormContents = useCallback<TRenderFormContents<TFormState>>(
    (
      isValid,
      formState,
      validationState,
      touchedState,
      onFieldChange,
      onFieldTouch
    ) => {
      return (
        <>
          <ConditionalWrapper condition={isInModal} Wrapper={Modal.Body}>
            <div className="space-y-4">
              <Field.Legacy
                name="name"
                validationState={
                  validationState?.['name']?.isValid === false &&
                  touchedState?.['name']
                    ? {
                        isValid: false,
                        validationMessages: validationState?.['name']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Name</Field.Label>
                <Input
                  value={formState.name || undefined}
                  onChange={(value) => {
                    onFieldChange({ name: value });
                  }}
                  onBlur={() => onFieldTouch?.('name')}
                />
              </Field.Legacy>
              <Field.Legacy
                name="email"
                validationState={
                  validationState?.['email']?.isValid === false &&
                  touchedState?.['email']
                    ? {
                        isValid: false,
                        validationMessages: validationState?.['email']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Email</Field.Label>
                <Input
                  value={formState.email || undefined}
                  onChange={(value) => {
                    onFieldChange({ email: value });
                  }}
                  onBlur={() => onFieldTouch?.('email')}
                />
              </Field.Legacy>
              <Field.Legacy
                name="phone"
                validationState={
                  validationState?.['phone']?.isValid === false &&
                  touchedState?.['phone']
                    ? {
                        isValid: false,
                        validationMessages: validationState?.['phone']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Telephone</Field.Label>
                <Input
                  value={formState.phone || undefined}
                  onChange={(value) => onFieldChange({ phone: value })}
                  onBlur={() => onFieldTouch?.('phone')}
                />
              </Field.Legacy>
              <Field.Legacy
                name="description"
                validationState={
                  validationState?.['description']?.isValid === false &&
                  touchedState?.['description']
                    ? {
                        isValid: false,
                        validationMessages:
                          validationState?.['description']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Description</Field.Label>
                <Input
                  value={formState.description || undefined}
                  onChange={(value) => onFieldChange({ description: value })}
                  onBlur={() => onFieldTouch?.('description')}
                />
              </Field.Legacy>
            </div>
          </ConditionalWrapper>

          <ConditionalWrapper condition={isInModal} Wrapper={Modal.Footer}>
            <ConditionalWrapper
              condition={isInModal}
              Wrapper={Modal.Footer.Actions}
            >
              <Button onClick={onClose} variant={EBtnVariant.Outline}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isValid}>
                Save
              </Button>
            </ConditionalWrapper>
          </ConditionalWrapper>
        </>
      );
    },
    [isInModal, onClose]
  );

  const onFormSubmit = useCallback<TOnFormSubmit<TFormState>>(
    (formState) => {
      if (!formState.name) {
        // should never get here
        return;
      }

      onSave?.({
        id: formState.id,
        name: formState.name,
        description: formState.description,
        email: formState.email,
        phone: formState.phone,
      });
    },
    [onSave]
  );

  return (
    <ValidatedForm
      initialFormState={initialFormState}
      fieldValidators={fieldValidators}
      renderFormContents={renderFormContents}
      onFormSubmit={onFormSubmit}
    />
  );
};

export default AddEditContactControl;
