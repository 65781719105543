import { Switch as HeadlessSwitch } from '@headlessui/react';

import useDeleteProposalLineItem from '@/api/mutations/proposalLineItem/useDeleteProposalLineItem';
import useUpdateProposalLineItem from '@/api/mutations/proposalLineItem/useUpdateProposalLineItem';
import useUpdateProposalLineItemMaterials from '@/api/mutations/proposalLineItem/useUpdateProposalLineItemMaterials';
import useGetMyTaxRates from '@/api/queries/me/useGetMyTaxRates';
import useGetProposalLineItem, {
  ProposalLineItemData,
} from '@/api/queries/proposalLineItems/useGetProposalLineItem';
import {
  ProposalLineItemSelectionType,
  UpdateProposalLineItemInput,
} from '@/gql/graphql';
import LineItemPriceCard, {
  LINE_ITEM_PRICE_FORM_SCHEMA,
  TLineItemPriceCardFormState,
} from '@/ui/components/addEditItemSidebar/fields/LineItemPriceCard';
import LineItemTotalsCard, {
  LINE_ITEM_TAXES_FORM_SCHEMA,
  TLineItemTaxesFormState,
} from '@/ui/components/addEditItemSidebar/fields/LineItemTotalsCard';
import ReferenceField, {
  LINE_ITEM_REFERENCE_FORM_SCHEMA,
  TLineItemReferenceFormState,
} from '@/ui/components/addEditItemSidebar/fields/ReferenceField';
import ThumbnailField, {
  LINE_ITEM_THUMBNAIL_FORM_SCHEMA,
  TLineItemThumbnailFormState,
} from '@/ui/components/addEditItemSidebar/fields/ThumbnailField';
import TitleDescriptionField, {
  LINE_ITEM_TITLE_DESCRIPTION_FORM_SCHEMA,
  TLineItemTitleDescriptionFormState,
} from '@/ui/components/addEditItemSidebar/fields/TitleDescriptionField';
import LinkedMaterialsList from '@/ui/components/addEditItemSidebar/LinkedMaterialsList';
import {
  calculateMarkupChange,
  calculatePredictedMaterialCosts,
} from '@/ui/components/addEditItemSidebar/utils';
import BuildMaterialsListSidebar, {
  MaterialState,
} from '@/ui/components/buildMaterialsListSidebar/BuildMaterialsListSidebar';
import PercentageField from '@/ui/components/percentageField/PercentageField';
import { zodResolver } from '@hookform/resolvers/zod';
import Conditional from '@payaca/components/conditional/Conditional';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import Field from '@payaca/components/plField/Field';
import RadioGroupField from '@payaca/components/plGroupField/RadioGroupField';
import Input from '@payaca/components/plInput/RawInput';
import Modal from '@payaca/components/plModal/Modal';
import Sidebar, {
  Props as SidebarProps,
} from '@payaca/components/plSidebar/Sidebar';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import Switch from '@payaca/components/plSwitch/Switch';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import { clstx } from '@payaca/components/utils';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, useEffect, useMemo, useReducer, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useScroll } from 'react-use';
import { z } from 'zod';

type TOwnProps = {
  rootPage?: 'proposal' | 'invoice';
  allowProposalLineItemSelectionType?: boolean;
  proposalLineItemId?: string;
  onRemoveSuccess?: () => void;
};

type TProps = Omit<SidebarProps, 'title'> &
  TOwnProps & {
    onSaveSuccess?: () => void;
  };

type TFormState = {
  quantity: number;
  selectionType: ProposalLineItemSelectionType;
  discountDescription?: string;
  materials: Pick<
    MaterialState,
    'id' | 'name' | 'image' | 'suppliedBy' | 'quantity'
  >[];
  canUpdateOriginalLineItem: boolean;
  updateOriginalLineItem: boolean;
} & TLineItemReferenceFormState &
  TLineItemPriceCardFormState &
  TLineItemTitleDescriptionFormState &
  TLineItemThumbnailFormState &
  TLineItemTaxesFormState;

const FORM_SCHEMA = z
  .object({
    selectionType: z.union([
      z.literal('REQUIRED'),
      z.literal('MULTIPLE_CHOICE'),
      z.literal('OPTIONAL'),
    ]),
    quantity: z.number(),
    discountDescription: z.string().optional(),
    materials: z.array(z.any()),
    canUpdateOriginalLineItem: z.boolean(),
    updateOriginalLineItem: z.boolean(),
  })
  .merge(LINE_ITEM_REFERENCE_FORM_SCHEMA)
  .merge(LINE_ITEM_PRICE_FORM_SCHEMA)
  .merge(LINE_ITEM_TITLE_DESCRIPTION_FORM_SCHEMA)
  .merge(LINE_ITEM_THUMBNAIL_FORM_SCHEMA)
  .merge(LINE_ITEM_TAXES_FORM_SCHEMA);

type ModalState = {
  modal?: 'CONFIRM_DELETE';
};

type ModalAction = { type: 'OPEN_CONFIRM_DELETE' } | { type: 'RESET' };

const modalStateReducer = (state: ModalState, action: ModalAction) => {
  switch (action.type) {
    case 'OPEN_CONFIRM_DELETE':
      return { modal: 'CONFIRM_DELETE' as const };
    case 'RESET':
      return {};
    default:
      return state;
  }
};

const buildDefaultValues = (
  proposalLineItemData: ProposalLineItemData
): TFormState => {
  const predictedMaterialCosts = calculatePredictedMaterialCosts(
    proposalLineItemData.materials.map((material) => ({
      materialQuantity: material.perUnitMaterialQuantity,
      // The backend orders the suppliers by 'preferred supplier' or lowest price
      price: material.material.suppliedBy[0]?.price.unitPriceExcTax.value || 0,
    }))
  );

  let markupTotals: TFormState['markupTotals'];
  if (typeof proposalLineItemData.price?.markupPercentage === 'number') {
    const profitPercentage =
      Math.round(
        (proposalLineItemData.price.markupPercentage /
          (100 + proposalLineItemData.price.markupPercentage)) *
          100 *
          100000
      ) / 100000;

    markupTotals = {
      markupType: 'profitPercentage',
      ...calculateMarkupChange(
        'profitPercentage',
        profitPercentage,
        predictedMaterialCosts
      ),
    };
  } else if (proposalLineItemData.price?.markupAmount) {
    markupTotals = {
      markupType: 'profitAmount',
      ...calculateMarkupChange(
        'profitAmount',
        proposalLineItemData.price.markupAmount.value,
        predictedMaterialCosts
      ),
    };
  } else {
    markupTotals = {
      markupType: 'price',
      ...calculateMarkupChange(
        'price',
        proposalLineItemData.price?.unitPriceExcTax.value || 0,
        predictedMaterialCosts
      ),
    };
  }

  return {
    thumbnail: proposalLineItemData.thumbnail
      ? {
          id: proposalLineItemData.thumbnail.id,
          file: undefined,
          preview: proposalLineItemData.thumbnail.url,
        }
      : undefined,
    reference: proposalLineItemData.internalReference || '',
    description: proposalLineItemData.description,
    quantity: proposalLineItemData.quantity,
    selectionType: proposalLineItemData.selection.type,
    taxRateId: proposalLineItemData.price.taxRate?.id || '',
    cis:
      typeof proposalLineItemData.price.cisDeductionRate === 'number'
        ? 'yes'
        : 'no',
    predictedMaterialCost: predictedMaterialCosts,
    markupTotals,
    autoUpdatePrice: true,
    hasDiscount: !!proposalLineItemData.price.discount,
    discountPercentage:
      proposalLineItemData.price.discount?.percentage || undefined,
    discountDescription:
      proposalLineItemData.price.discount?.description || undefined,
    materials: proposalLineItemData.materials.map((i) => ({
      id: i.material.id,
      name: i.material.name,
      image: i.material.image,
      suppliedBy: i.material.suppliedBy,
      quantity: i.perUnitMaterialQuantity,
    })),
    canUpdateOriginalLineItem: !!proposalLineItemData.lineItem?.id,
    updateOriginalLineItem: false,
  };
};

const useDefaultValues = (proposalLineItemData?: ProposalLineItemData) => {
  const { defaultTaxRate } = useGetMyTaxRates();

  return useMemo(() => {
    if (!proposalLineItemData) {
      return {
        reference: '',
        description: '',
        taxRateId: defaultTaxRate?.id || '',
        cis: 'no',
        quantity: 1,
        markupTotals: {
          markupType: 'price' as const,
          profitPercentage: 0,
          profitAmount: 0,
          price: 0,
        },
        autoUpdatePrice: true,
        selectionType: 'REQUIRED' as const,
        hasDiscount: false,
        materials: [],
        canUpdateOriginalLineItem: false,
        updateOriginalLineItem: false,
      };
    }

    return buildDefaultValues(proposalLineItemData);
  }, [proposalLineItemData]);
};

const AddEditProposalLineItemContent: FC<
  {
    proposalLineItemData?: ProposalLineItemData;
    setIsDirty: (isDirty: boolean) => void;
    isLinkMaterialSidebarOpen: boolean;
    setIsLinkMaterialSidebarOpen: (state: boolean) => void;
    onSubmit: (state: TFormState) => Promise<void>;
    onThumbnailDelete: (thumbnailId: string) => Promise<void>;
    onDelete: () => Promise<void>;
  } & TOwnProps
> = (props) => {
  const {
    rootPage = 'proposal',
    proposalLineItemId,
    proposalLineItemData,
    isLinkMaterialSidebarOpen,
    setIsLinkMaterialSidebarOpen,
    allowProposalLineItemSelectionType = false,
    setIsDirty,
    onSubmit,
    onThumbnailDelete,
    onDelete,
  } = props;

  /**
   * State
   */
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  useScroll({ current: scrollRef });
  const [isSaving, setIsSaving] = useState(false);
  const [isDeletingLineItem, setIsDeletingLineItem] = useState(false);

  const [modalState, modalStateDispatch] = useReducer(modalStateReducer, {});

  const defaultValues = useDefaultValues(proposalLineItemData);
  const formMethods = useForm<TFormState>({
    resolver: zodResolver(FORM_SCHEMA),
    defaultValues,
  });

  // Annoying to have to cascade this up.
  // But we need to reset the form state when the user closes the sidebar
  // and the best way to do that is for the `useForm` hook to unmount and
  // mount each time the sidebar opens
  // (meaning the useForm hook can't be in the parent component)
  useEffect(() => {
    setIsDirty(formMethods.formState.isDirty);
  }, [formMethods.formState.isDirty]);

  const handleSubmit = async (state: TFormState) => {
    setIsSaving(true);

    try {
      await onSubmit(state);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleThumbnailDelete = async () => {
    const thumbnailId = formMethods.getValues('thumbnail.id');

    formMethods.setValue('thumbnail', undefined, {
      shouldDirty: true,
    });

    if (thumbnailId) {
      await onThumbnailDelete(thumbnailId);
    }
  };

  const handleDeleteItem = async () => {
    setIsDeletingLineItem(true);

    await onDelete();

    setIsDeletingLineItem(false);
    modalStateDispatch({ type: 'RESET' });
  };

  // todo: dry with AddEditItemSidebar.tsx
  const handleLinkedMaterialsChange = (
    newLinkedMaterials: TFormState['materials']
  ) => {
    const predictedMaterialCosts = calculatePredictedMaterialCosts(
      newLinkedMaterials.map((material) => ({
        materialQuantity: material.quantity,
        // The backend orders the suppliers by 'preferred supplier' or lowest price
        price: material.suppliedBy[0]?.price.unitPriceExcTax.value || 0,
      }))
    );

    formMethods.setValue('materials', newLinkedMaterials, {
      shouldDirty: true,
    });

    let markupTotalsType = formMethods.getValues('markupTotals.markupType');
    let calculatedMarkupChange = calculateMarkupChange(
      markupTotalsType,
      formMethods.getValues(`markupTotals.${markupTotalsType}`),
      predictedMaterialCosts
    );

    if (newLinkedMaterials.length === 0) {
      markupTotalsType = 'price';
      calculatedMarkupChange = {
        profitPercentage: 0,
        profitAmount: 0,
        price: 0,
      };
    }

    formMethods.setValue(
      'markupTotals',
      {
        markupType: markupTotalsType,
        ...calculatedMarkupChange,
      },
      {
        shouldDirty: true,
      }
    );

    formMethods.setValue('predictedMaterialCost', predictedMaterialCosts, {
      shouldDirty: true,
    });
  };

  const unitPriceExcTax = formMethods.watch('markupTotals.price');
  const hasDiscount = formMethods.watch('hasDiscount');
  const linkedMaterials = formMethods.watch('materials');
  const canUpdateOriginalLineItem = formMethods.watch(
    'canUpdateOriginalLineItem'
  );

  const numOfLinkedMaterials = linkedMaterials.reduce((acc, linkedMaterial) => {
    return acc + linkedMaterial.quantity;
  }, 0);

  const hasScrollShadow = !(
    scrollRef &&
    scrollRef.scrollHeight - scrollRef.scrollTop === scrollRef.clientHeight
  );

  return (
    <>
      <div className="grid h-full grid-cols-2 flex-grow overflow-auto">
        <div className="overflow-auto" ref={setScrollRef}>
          <FormProvider {...formMethods}>
            <form
              className="flex h-full flex-col"
              onSubmit={formMethods.handleSubmit(handleSubmit, console.error)}
            >
              <div className="flex flex-col gap-4 p-3.5">
                <div className="grid sm:grid-cols-4 gap-4">
                  <ReferenceField className="sm:col-span-3" />

                  <Field.Legacy>
                    <Field.Label>Quantity</Field.Label>

                    <Input
                      type="number"
                      step="any"
                      onWheel={(e) => e.currentTarget.blur()}
                      {...formMethods.register('quantity', {
                        valueAsNumber: true,
                      })}
                    />
                  </Field.Legacy>
                </div>

                <TitleDescriptionField />

                <Conditional condition={allowProposalLineItemSelectionType}>
                  <Field.Legacy name="selectionType">
                    <Field.Label>Selection type</Field.Label>

                    <Controller
                      name="selectionType"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <RadioGroupField
                            value={value}
                            onChange={({ selectionType }) => {
                              onChange(selectionType);
                            }}
                            horizontal
                            options={[
                              { label: 'Required', value: 'REQUIRED' },
                              {
                                label: 'Multiple Choice',
                                value: 'MULTIPLE_CHOICE',
                              },
                              { label: 'Optional', value: 'OPTIONAL' },
                            ]}
                          />
                        );
                      }}
                    />
                  </Field.Legacy>
                </Conditional>

                <LineItemPriceCard
                  hasLinkedMaterials={numOfLinkedMaterials > 0}
                />

                <Card sizeVariant={CardSizeVariant.SM}>
                  <Card.Body>
                    <HeadlessSwitch.Group>
                      <HeadlessSwitch.Label className="flex justify-between items-center cursor-pointer">
                        <label>Discount</label>
                        <Controller
                          render={({ field: { onChange, value } }) => {
                            return <Switch value={value} onChange={onChange} />;
                          }}
                          name="hasDiscount"
                        />
                      </HeadlessSwitch.Label>
                    </HeadlessSwitch.Group>

                    <Conditional condition={hasDiscount}>
                      <div className="space-y-4 mt-4">
                        <Field.Legacy>
                          <Controller
                            render={({ field: { onChange, value } }) => {
                              return (
                                <PercentageField
                                  value={value}
                                  onChange={onChange}
                                  minErrorMessages={[
                                    'Discount percentage must be 0 or more',
                                  ]}
                                  maxErrorMessages={[
                                    'Discount percentage must be 100 or less',
                                  ]}
                                />
                              );
                            }}
                            name="discountPercentage"
                          />
                        </Field.Legacy>

                        <Field.Legacy>
                          <Field.Label>Discount description</Field.Label>

                          <Input
                            {...formMethods.register('discountDescription')}
                          />
                        </Field.Legacy>
                      </div>
                    </Conditional>
                  </Card.Body>
                </Card>

                <LineItemTotalsCard unitPriceExcTax={unitPriceExcTax} />

                <ThumbnailField onDelete={handleThumbnailDelete} />
              </div>

              <div
                className={clstx(
                  'sticky bottom-0 z-10 mt-auto w-full p-3.5 bg-white',
                  hasScrollShadow && 'shadow-flipped-lg'
                )}
              >
                <Conditional condition={canUpdateOriginalLineItem}>
                  <Tooltip
                    tooltipContent={
                      <p className="flex items-center">
                        <UntitledIcon
                          name="alert-triangle.3"
                          className="w-5 h-5 mr-1 text-yellow-500"
                        />
                        This will also update the linked Materials on the
                        original Item
                      </p>
                    }
                  >
                    <Checkbox
                      className="mb-4"
                      label="Update original Item"
                      {...formMethods.register('updateOriginalLineItem')}
                    />
                  </Tooltip>
                </Conditional>

                <Button
                  className="w-full mb-2"
                  type="submit"
                  disabled={isSaving || !formMethods.formState.isDirty}
                  isProcessing={isSaving}
                >
                  {proposalLineItemId ? 'Update Item' : 'Create Item'}
                </Button>

                <Button
                  className="w-full"
                  colour={EBtnColour.Red}
                  variant={EBtnVariant.Outline}
                  onClick={() => {
                    modalStateDispatch({ type: 'OPEN_CONFIRM_DELETE' });
                  }}
                >
                  Remove Item
                </Button>
              </div>
            </form>
          </FormProvider>
        </div>

        <div className="flex flex-col border-l bg-gray-50 p-3.5 overflow-auto">
          <LinkedMaterialsList
            titleText="Linked Materials per unit"
            linkedMaterials={linkedMaterials}
            onLinkMaterialsRequest={() => setIsLinkMaterialSidebarOpen(true)}
            onChange={handleLinkedMaterialsChange}
          />
        </div>
      </div>

      <Modal
        isOpen={modalState.modal === 'CONFIRM_DELETE'}
        title="Remove Item"
        onClose={() => {
          modalStateDispatch({ type: 'RESET' });
        }}
      >
        <Modal.Body>
          <p>
            Are you sure you wish to remove this Item from the{' '}
            {rootPage === 'proposal' ? 'Proposal' : 'Invoice'}?
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            <Button
              variant={EBtnVariant.Outline}
              onClick={() => {
                modalStateDispatch({ type: 'RESET' });
              }}
            >
              Cancel
            </Button>
            <Button
              colour={EBtnColour.Red}
              onClick={handleDeleteItem}
              isProcessing={isDeletingLineItem}
              disabled={isDeletingLineItem}
            >
              Remove Item
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </Modal>

      <BuildMaterialsListSidebar
        title="Link Materials"
        showPriceIncludingTax={false}
        emptyStatePromptText="Link Materials to Item"
        primaryActionText="Link Materials to Item"
        warnUserContent={{
          body: "You have selected Materials that you haven't linked to the Item.",
        }}
        zIndexLevel={2}
        onAddMaterials={(incomingMaterials) => {
          const merged = new Map(linkedMaterials.map((i) => [i['id'], i]));

          // Add or merge quantities with incoming materials
          incomingMaterials.forEach((i) => {
            merged.set(i['id'], {
              ...merged.get(i['id']),
              ...i,
              quantity: (merged.get(i['id'])?.quantity || 0) + i.quantity,
            });
          });

          handleLinkedMaterialsChange(Array.from(merged.values()));

          return Promise.resolve();
        }}
        isOpen={isLinkMaterialSidebarOpen}
        onClose={() => setIsLinkMaterialSidebarOpen(false)}
      />
    </>
  );
};

const EditProposalLineItemSidebar: FC<TProps> = (props) => {
  const {
    rootPage,
    proposalLineItemId,
    onRemoveSuccess,
    onSaveSuccess,
    allowProposalLineItemSelectionType,
    onClose,
    ...rest
  } = props;

  /**
   * State
   */
  const [isLinkMaterialSidebarOpen, setIsLinkMaterialSidebarOpen] =
    useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [shouldWarnUserOnClose, setShouldWarnUserOnClose] = useState(false);

  /**
   * redux
   */
  const dispatch = useDispatch();

  /**
   * Queries
   */
  const { data: proposalLineItemData, isFetching } = useGetProposalLineItem(
    {
      proposalLineItemId,
    },
    {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  /**
   * Mutations
   */
  const { mutateAsync: updateProposalLineItemMutation } =
    useUpdateProposalLineItem();
  const { mutateAsync: updateProposalLineItemMaterialsMutation } =
    useUpdateProposalLineItemMaterials();
  const { mutateAsync: deleteProposalLineItemMutation } =
    useDeleteProposalLineItem();

  const onSubmit = async (state: TFormState) => {
    if (!proposalLineItemId) {
      return;
    }

    setIsSaving(true);

    // Make sure that the thumbnail is uploaded before we update the line item
    // So that if the user wishes to update the original line item, the new thumbnail is attached too
    if (state.thumbnail?.file) {
      await new Promise<void>((resolve, reject) => {
        dispatch(
          jobContentActions.requestAddAttachmentToJobLineItem(
            +proposalLineItemId,
            {
              file: state.thumbnail!.file!,
              fileName: state.thumbnail!.file!.name,
            },
            false,
            (error?: Error) => {
              if (error) {
                reject(error);
              } else {
                resolve();
              }
            }
          )
        );
      });
    }

    const removedLinkedMaterials =
      proposalLineItemData?.proposalLineItem.materials.filter((material) => {
        return !state.materials.some(
          (newMaterial) => newMaterial.id === material.material.id
        );
      }) || [];

    await updateProposalLineItemMaterialsMutation({
      proposalLineItemId,
      proposalLineItemMaterials: [
        ...removedLinkedMaterials.map((material) => ({
          materialId: material.material.id,
          quantityChange: {
            absolute: 0,
          },
        })),
        ...state.materials.map((material) => ({
          materialId: material.id,
          quantityChange: {
            absolute: material.quantity,
          },
        })),
      ],
      updateLineItem:
        state.canUpdateOriginalLineItem && state.updateOriginalLineItem,
    });

    const price: UpdateProposalLineItemInput['price'] = {
      taxRateId: state.taxRateId,
    };
    // todo: dry with AddEditItemSidebar.tsx
    switch (true) {
      case state.markupTotals.markupType === 'profitPercentage' &&
        state.autoUpdatePrice: {
        const profitFactor = (state.markupTotals.profitPercentage || 0) / 100;
        const markupPercentage =
          profitFactor !== 0 ? (profitFactor / (1 - profitFactor)) * 100 : 0;

        price['markupPercentage'] = markupPercentage;
        break;
      }
      case state.markupTotals.markupType === 'profitAmount' &&
        state.autoUpdatePrice:
        price['markupAmount'] = state.markupTotals.profitAmount;
        break;
      default:
        price['unitPriceExcTax'] = state.markupTotals.price;
        break;
    }

    await updateProposalLineItemMutation({
      id: proposalLineItemId,
      internalReference: state.reference,
      description: state.description,
      selection: {
        type: state.selectionType,
      },
      quantity: state.quantity,
      price: {
        ...price,
        cisApplies: state.cis === 'yes',
        discount: state.hasDiscount
          ? {
              percentage: state.discountPercentage || 0,
              description: state.discountDescription,
            }
          : null,
      },
      updateLineItem:
        state.canUpdateOriginalLineItem && state.updateOriginalLineItem,
    });

    setIsSaving(false);
    onSaveSuccess?.();
  };

  const handleThumbnailDelete = async (thumbnailId: string) => {
    if (!proposalLineItemId) {
      return;
    }

    await new Promise<void>((resolve, reject) => {
      dispatch(
        jobContentActions.requestRemoveAttachmentFromJobLineItem(
          +proposalLineItemId,
          thumbnailId,
          false,
          (error?: Error) => {
            if (error) {
              reject(error);
            } else {
              resolve();
            }
          }
        )
      );
    });
  };

  const onProposalLineItemRemove = async () => {
    if (!proposalLineItemId) {
      return;
    }

    await deleteProposalLineItemMutation({
      proposalLineItemId: proposalLineItemId,
    });

    onRemoveSuccess?.();
  };

  const isLoading =
    !!proposalLineItemId && (isFetching || !proposalLineItemData);

  return (
    <Sidebar
      title={proposalLineItemId ? 'Edit Item' : 'Add Item'}
      warnUserOnClose={shouldWarnUserOnClose && !isSaving}
      warnUserContent={{
        body: 'The changes to this Item are not saved and will be lost.',
        confirm: 'Discard changes',
      }}
      behind={isLinkMaterialSidebarOpen}
      onClose={isSaving ? undefined : onClose}
      size="lg"
      {...rest}
    >
      {isLoading ? (
        <>
          <div className="grid h-full grid-cols-2">
            <div className="flex flex-col gap-4 p-3.5">
              <SkeletonLoader.Input />
              <SkeletonLoader.Textarea />
              <SkeletonLoader.Input />
              <SkeletonLoader.Input />

              <div className="mt-auto">
                <SkeletonLoader.Button />
              </div>
            </div>
            <div className="space-y-4 border-l bg-gray-50  p-3.5">
              <SkeletonLoader.MaterialCard />
              <SkeletonLoader.MaterialCard />
              <SkeletonLoader.MaterialCard />
              <SkeletonLoader.MaterialCard />
              <SkeletonLoader.MaterialCard />
              <SkeletonLoader.MaterialCard />
              <SkeletonLoader.MaterialCard />
            </div>
          </div>
        </>
      ) : (
        <AddEditProposalLineItemContent
          rootPage={rootPage}
          proposalLineItemId={proposalLineItemId}
          proposalLineItemData={proposalLineItemData?.proposalLineItem}
          isLinkMaterialSidebarOpen={isLinkMaterialSidebarOpen}
          setIsLinkMaterialSidebarOpen={setIsLinkMaterialSidebarOpen}
          onSubmit={onSubmit}
          onThumbnailDelete={handleThumbnailDelete}
          onDelete={onProposalLineItemRemove}
          allowProposalLineItemSelectionType={
            allowProposalLineItemSelectionType
          }
          setIsDirty={setShouldWarnUserOnClose}
        />
      )}
    </Sidebar>
  );
};

export default EditProposalLineItemSidebar;
