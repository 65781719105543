import Field from '@payaca/components/plField/Field';
import { get as _get } from 'lodash-es';
import { FC } from 'react';
import { useParams } from 'react-router';
import useGetConnectionApplication from '../../../../api/queries/connectionApplication/useGetConnectionApplication';
import { Props as DyanmicFieldProps } from '../DynamicField';
import FieldSet from '../FieldSet';
import {
  getFieldLabelFromFieldSchema,
  getSchemaDataForDiscriminatorField,
} from '../utils';

type Props = Omit<DyanmicFieldProps, 'dependentFields'>;
const DiscriminatorFieldReadOnly: FC<Props> = (props) => {
  const { definitions, fieldSchema, name: namespace } = props;
  // discriminator object containing property and mapping refs
  const discriminatorObj = fieldSchema.discriminator;

  // name of discriminator property
  const discriminatorPropertyName = discriminatorObj.propertyName;

  // name for the nested field
  const nestedName = `${namespace}.${discriminatorPropertyName}`;

  const { connectionApplicationId } = useParams<{
    projectId: string;
    connectionApplicationId: string;
  }>();

  const { data: persistedFormStateData } = useGetConnectionApplication(
    connectionApplicationId,
    false
  );
  const persistedFormState = persistedFormStateData?.connectionApplication.data;

  // get the value of the discriminator property
  const discriminatorPropertyValue = _get(persistedFormState, nestedName);

  // get the nested schema for the selected discriminator value
  const nestedSchemaData = getSchemaDataForDiscriminatorField(
    fieldSchema.discriminator,
    definitions,
    discriminatorPropertyValue
  );

  const fieldLabel = getFieldLabelFromFieldSchema(fieldSchema);

  return (
    <>
      <Field.Legacy>
        <Field.Label>{fieldLabel}</Field.Label>
        <p>{discriminatorPropertyValue}</p>
      </Field.Legacy>

      {/* conditional fields based on discriminator */}
      {nestedSchemaData && (
        <FieldSet
          namespace={namespace}
          data={nestedSchemaData}
          definitions={definitions}
          readonly
        />
      )}
    </>
  );
};

export default DiscriminatorFieldReadOnly;
