import { useSelector } from '@/api/state';
import { actions as usersActions } from '@/api/users';
import BasicField from '@payaca/components/basicField/BasicField';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import FormSignatureCaptureControl from '@payaca/components/formSignatureCaptureControl/FormSignatureCaptureControl';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Modal from '@payaca/components/plModal/Modal';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import {
  getEmailFieldValidator,
  getIsRequiredFieldValidator,
  getPhoneNumberFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import * as userActions from '@payaca/store/user/userActions';
import { AccountRegions } from '@payaca/types/accountTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { isEqual } from 'lodash-es';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import useGetMe from '../../../api/queries/me/useGetMe';
import ChangePasswordForm from '../changePasswordForm/ChangePasswordForm';
import LoqateAdvisoryEmailInputField from '../loqateAdvisoryEmailInputField/LoqateAdvisoryEmailInputField';
import LoqateAdvisoryPhoneInputField from '../loqateAdvisoryPhoneInputField/LoqateAdvisoryPhoneInputField';
import './PersonalInformationForm.sass';

const formatContactNumber = (phone?: string) =>
  phone && phone[0] !== '+' ? `+${phone}` : phone;

const PersonalInformationForm: FunctionComponent = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [discardChangesTimestamp, setDiscardChangesTimestamp] =
    useState<Date>();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { data: me } = useGetMe();

  const myProfile = useSelector((state: any) => {
    return state.users.myProfile;
  });
  const isUpdatingProfile = useSelector(
    (state) => state.user.isUpdatingProfile
  );

  const initialFormState = useMemo(() => {
    return {
      firstname: myProfile?.firstname,
      lastname: myProfile?.lastname,
      email: myProfile?.pendingEmail || myProfile?.email,
      phoneNumber: myProfile?.contactNumber,
      discardChangesTimestamp: discardChangesTimestamp,
      gasSafeIdCardNumber: myProfile?.gasSafeIdCardNumber,
      userSignature: myProfile?.userSignature,
    };
  }, [myProfile, discardChangesTimestamp]);

  const fieldValidators = useMemo(() => {
    const isRequiredFieldValidator = getIsRequiredFieldValidator();

    return {
      firstname: [isRequiredFieldValidator],
      lastname: [isRequiredFieldValidator],
      email: [isRequiredFieldValidator, getEmailFieldValidator()],
      phoneNumber: [isRequiredFieldValidator, getPhoneNumberFieldValidator()],
    };
  }, []);

  const onSubmit = useCallback(
    (formState: { [key: string]: any }) => {
      setErrorMessage(undefined);
      const payload = {
        firstname: formState.firstname,
        lastname: formState.lastname,
        pendingEmail:
          formState.email !== initialFormState.email
            ? formState.email
            : undefined, // only send email update if form email is different to initial email
        contactNumber: formatContactNumber(formState.phoneNumber),
        gasSafeIdCardNumber: formState.gasSafeIdCardNumber,
        userSignature: formState.userSignature,
      };
      dispatch(
        userActions.requestUpdateProfile(payload, (error) => {
          dispatch(usersActions.getProfile());
          if (error) {
            if (error?.errors === 'VERIFY_EMAIL_FAILURE') {
              // specific email from attempting to send verification email to new email address
              setErrorMessage(
                'Sorry, there was an error sending your updated email a verification email. Please check your email address and try again.'
              );
            } else {
              setErrorMessage(
                'Sorry, there was an error updating your details. Please try again.'
              );
            }
          }
        })
      );
    },
    [initialFormState, myProfile]
  );

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const showGasSafe = useMemo(
    () => account.gasSafeRegNo && [AccountRegions.UK].includes(account.region),
    [account]
  );

  const getFieldValidationState = (
    validationState: {
      [key: string]: FieldValidationResult;
    },
    name: string
  ) => {
    return validationState?.[name]?.isValid === false
      ? {
          isValid: false,
          validationMessages: validationState?.[name]?.errors,
        }
      : undefined;
  };

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      const hasAnyChanges = !isEqual(formState, initialFormState);

      return (
        <>
          <div className="flex flex-grow flex-col gap-4">
            <Field.Legacy
              name="firstname"
              validationState={getFieldValidationState(
                validationState,
                'firstname'
              )}
            >
              <Field.Label>First name</Field.Label>
              <Input
                value={formState.firstname}
                onChange={(value) => onFieldChange({ firstname: value })}
                onTouch={() => onFieldTouch('firstname')}
              />
            </Field.Legacy>
            <Field.Legacy
              name="lastname"
              validationState={getFieldValidationState(
                validationState,
                'lastname'
              )}
            >
              <Field.Label>Last name</Field.Label>
              <Input
                value={formState.lastname}
                onChange={(value) => onFieldChange({ lastname: value })}
                onTouch={() => onFieldTouch('lastname')}
              />
            </Field.Legacy>
            <Field.Legacy>
              <LoqateAdvisoryPhoneInputField
                styleVariant={InputStyleVariant.OUTSIZE}
                label={'Phone number'}
                value={formState.phoneNumber}
                name="phoneNumber"
                onChange={onFieldChange}
                onTouch={onFieldTouch}
                disableValidation={
                  validationState['phoneNumber']
                    ? !validationState['phoneNumber'].isValid
                    : false
                }
              />
            </Field.Legacy>
            <Field.Legacy>
              <LoqateAdvisoryEmailInputField
                styleVariant={InputStyleVariant.OUTSIZE}
                label={'Email address'}
                value={formState.email}
                name="email"
                onChange={onFieldChange}
                onTouch={onFieldTouch}
                disableValidation={
                  validationState['email']
                    ? !validationState['email'].isValid
                    : false
                }
                isDisabled={!!me?.user.account.enforceSSO}
              />
            </Field.Legacy>
          </div>

          {myProfile?.pendingEmail && (
            <ErrorMessage
              message={`This email address is currently awaiting verification. Please verify to start using it.`}
            />
          )}
          {!me?.user.account.enforceSSO && (
            <div className="mt-3">
              <Button
                variant={EBtnVariant.LinkInline}
                onClick={() => setShowChangePasswordModal(true)}
              >
                Change password
              </Button>
            </div>
          )}

          <hr />

          <div>
            <h4>Additional information</h4>
            <div className="flex flex-grow flex-col gap-4">
              {showGasSafe && (
                <>
                  <BasicField
                    type={'number'}
                    styleVariant={InputStyleVariant.OUTSIZE}
                    label="Gas Safe ID Card Number"
                    name="gasSafeIdCardNumber"
                    value={formState.gasSafeIdCardNumber}
                    onTouch={onFieldTouch}
                    onChange={onFieldChange}
                  />
                </>
              )}

              <FormSignatureCaptureControl
                label={
                  'Please provide a signature that you can use in industry forms'
                }
                currentSignature={formState.userSignature}
                onResponseCaptureComplete={(value: any) =>
                  onFieldChange({ userSignature: value })
                }
              />
            </div>
          </div>

          <hr />

          <div className="flex items-start justify-between">
            <div>
              <Button
                disabled={!hasAnyChanges || !isValid}
                isProcessing={isUpdatingProfile}
                onClick={() => !isUpdatingProfile && onSubmit(formState)}
              >
                Save changes
              </Button>
              {errorMessage && (
                <ErrorMessage message={errorMessage} className="mt-4" />
              )}
            </div>

            <Button
              variant={EBtnVariant.Link}
              disabled={!hasAnyChanges}
              onClick={() => setDiscardChangesTimestamp(new Date())}
            >
              Discard changes
            </Button>
          </div>
          <Prompt
            when={hasAnyChanges}
            message="There are unsaved changes on the page, are you sure you want to leave?"
          />
        </>
      );
    },
    [
      onSubmit,
      isUpdatingProfile,
      initialFormState,
      errorMessage,
      showGasSafe,
      myProfile,
    ]
  );

  return (
    <ResponsiveViewWrapper
      className="personal-information-form-container"
      downBreakpointSm={500}
    >
      <>
        <ValidatedForm<{ [key: string]: any }>
          renderFormContents={renderFormContents}
          initialFormState={initialFormState}
          fieldValidators={fieldValidators}
        />
      </>
      <Modal
        isOpen={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
        title="Change password"
      >
        <ChangePasswordForm
          onSuccessCallback={() => setShowChangePasswordModal(false)}
        />
      </Modal>
    </ResponsiveViewWrapper>
  );
};

export default PersonalInformationForm;
