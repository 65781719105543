import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ValidationFeedbackBlock from '@payaca/components/validationFeedbackBlock/ValidationFeedbackBlock';
import { getArrayMustNotBeEmptyFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';

import useCreateAddress from '@/api/mutations/addresses/useCreateAddress';
import purchaseOrderKeys from '@/api/queries/purchase-orders/keyFactory';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import { DateInput } from '@payaca/components/plDateInput/DateInput';
import Field from '@payaca/components/plField/Field';
import Modal from '@payaca/components/plModal/Modal';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import {
  useMaterialPurchaseIntentsForPurchaseOrder,
  usePurchaseOrder,
  useSupplier,
} from '@payaca/store/hooks/appState';
import { requestPersistPurchaseOrder } from '@payaca/store/materialsList/materialsListActions';
import { PersistPurchaseOrderRequestData } from '@payaca/store/materialsList/materialsListTypes';
import { Deal } from '@payaca/types/dealTypes';
import { PartialAddress } from '@payaca/types/locationTypes';
import { MaterialPurchaseIntent } from '@payaca/types/materialsListTypes';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { PurchaseOrderDeliveryAddressControl } from '../purchaseOrderDeliveryAddressControl/PurchaseOrderDeliveryAddressControl';
import PurchaseOrderMaterialPurchaseIntentsControl from '../purchaseOrderMaterialPurchaseIntentsControl/PurchaseOrderMaterialPurchaseIntentsControl';
import './EditPurchaseOrderControl.sass';

export enum FormSection {
  SUPPLIER_SELECTION = 'supplierSelection',
  MATERIALS_SELECTION = 'materialsSelection',
}

type Props = {
  purchaseOrderId: number;
  onEditPurchaseOrderSuccess?: () => void;
  projectId?: Deal['id'];
};
const EditPurchaseOrderControl: FC<Props> = ({
  purchaseOrderId,
  onEditPurchaseOrderSuccess,
  projectId,
}: PropsWithChildren<Props>): JSX.Element => {
  // TODO need to get materialslist data before rendering this - need materials, materialslistMaterials, etc
  const dispatch = useDispatch();
  const [showValidationFeedback, setShowValidationFeedback] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const purchaseOrder = usePurchaseOrder(purchaseOrderId);

  const { mutateAsync: mutateCreateAddress } = useCreateAddress();
  const queryClient = useQueryClient();

  const fieldValidators = useMemo(() => {
    return {
      materialPurchaseIntents: [
        getArrayMustNotBeEmptyFieldValidator({
          customErrorMessage: 'You cannot save an empty purchase order',
        }),
      ],
    };
  }, []);

  const materialPurchaseIntents =
    useMaterialPurchaseIntentsForPurchaseOrder(purchaseOrderId);

  const supplier = useSupplier(purchaseOrder?.supplierId);

  const initialFormState = useMemo(() => {
    return {
      id: purchaseOrderId,
      additionalNotes: purchaseOrder?.additionalNotes || '',
      dueAt: purchaseOrder?.dueAt ? new Date(purchaseOrder.dueAt) : undefined,
      deliveryAddressId: purchaseOrder?.deliveryAddressId || undefined,
      materialPurchaseIntents: materialPurchaseIntents.map(
        (x: MaterialPurchaseIntent) => {
          return {
            id: x.id,
            materialsListMaterialId: x.materialsListMaterialId,
            materialQuantity: x.materialQuantity,
          };
        }
      ),
      showMaterialPrices: purchaseOrder?.showMaterialPrices || false,
    };
  }, [materialPurchaseIntents.length, !!purchaseOrder, purchaseOrderId]);

  const onSubmit = useCallback(
    async (
      requestData: PersistPurchaseOrderRequestData & {
        newDeliveryAddress?: PartialAddress;
      }
    ) => {
      setIsProcessing(true);
      let deliveryAddressId: number | undefined = requestData.deliveryAddressId;

      // create the address if it doesn't exist already
      if (requestData.newDeliveryAddress) {
        // TODO in future: if switching from one address to another, consider deleting the previous address is=f it is now orphanned
        const {
          createAddress: { id: addressId },
        } = await mutateCreateAddress({
          line1: requestData.newDeliveryAddress.line1,
          line2: requestData.newDeliveryAddress.line2,
          city: requestData.newDeliveryAddress.city,
          postalCode: requestData.newDeliveryAddress.postcode,
          country: requestData.newDeliveryAddress.country,
        });

        if (addressId) {
          deliveryAddressId = +addressId;
        }
      }

      dispatch(
        requestPersistPurchaseOrder(
          { ...requestData, deliveryAddressId: deliveryAddressId },
          (purchaseOrderId: number) => {
            if (deliveryAddressId) {
              void queryClient.invalidateQueries({
                queryKey:
                  purchaseOrderKeys.purchaseOrderAddress(purchaseOrderId),
              });
            }

            onEditPurchaseOrderSuccess?.();
            setIsProcessing(false);
          }
        )
      );
    },
    [onEditPurchaseOrderSuccess]
  );

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <Modal.Body>
            <div className="form-body">
              <p className="selected-supplier-indicator">
                Supplier: <strong>{supplier?.name}</strong>
              </p>
              {purchaseOrder && (
                <PurchaseOrderMaterialPurchaseIntentsControl
                  supplierId={purchaseOrder.supplierId}
                  materialsListId={purchaseOrder.materialsListId}
                  materialPurchaseIntents={formState.materialPurchaseIntents}
                  onChange={(materialPurchaseIntents) =>
                    onFieldChange({
                      materialPurchaseIntents: materialPurchaseIntents,
                    })
                  }
                  purchaseOrderId={purchaseOrder?.id}
                />
              )}
              {showValidationFeedback && (
                <ValidationFeedbackBlock
                  validationResults={[validationState.materialPurchaseIntents]}
                  isDismissable={true}
                  onDismissed={() => setShowValidationFeedback(false)}
                />
              )}
              <Field.Legacy name="dueAt">
                <Field.Label>Due by</Field.Label>
                <DateInput
                  value={formState.dueAt}
                  onChange={(value) => {
                    onFieldChange({ dueAt: value });
                    onFieldTouch('dueAt');
                  }}
                />
              </Field.Legacy>

              <Field.Legacy name="deliveryAddressId">
                <Field.Label>Deliver to</Field.Label>
                <PurchaseOrderDeliveryAddressControl
                  purchaseOrderId={purchaseOrderId}
                  supplierId={purchaseOrder?.supplierId}
                  projectId={projectId}
                  onChange={onFieldChange}
                  value={{
                    deliveryAddressId: formState.deliveryAddressId,
                    newDeliveryAddress: formState.newDeliveryAddress,
                  }}
                />
              </Field.Legacy>

              <Field.Legacy name="additionalNotes">
                <Field.Label>Additional notes</Field.Label>
                <Textarea
                  onChange={(value) => {
                    onFieldChange({ additionalNotes: value });
                  }}
                  onTouch={() => onFieldTouch('additionalNotes')}
                  value={formState.additionalNotes}
                />
              </Field.Legacy>
              <Field.Legacy name="showMaterialPrices">
                <Checkbox
                  checked={formState.showMaterialPrices}
                  onChange={() =>
                    onFieldChange({
                      showMaterialPrices: !formState.showMaterialPrices,
                    })
                  }
                  label="Show Material prices on Purchase Order"
                />
              </Field.Legacy>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Footer.Actions>
              <Button
                styleVariant={ButtonStyleVariant.OUTSIZE}
                isProcessing={isProcessing}
                onClick={() =>
                  isValid
                    ? !isProcessing &&
                      onSubmit(
                        formState as PersistPurchaseOrderRequestData & {
                          newDeliveryAddress?: PartialAddress;
                        }
                      )
                    : setShowValidationFeedback(true)
                }
              >
                Save purchase order
              </Button>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </>
      );
    },
    [
      showValidationFeedback,
      supplier?.name,
      onSubmit,
      isProcessing,
      purchaseOrder,
    ]
  );

  return (
    <div className="edit-purchase-order-control">
      <ValidatedForm<
        PersistPurchaseOrderRequestData & {
          newDeliveryAddress?: PartialAddress;
        }
      >
        fieldValidators={fieldValidators}
        renderFormContents={renderFormContents}
        initialFormState={initialFormState}
      />
    </div>
  );
};

export default EditPurchaseOrderControl;
