import { FC, useEffect } from 'react';

import Field from '@payaca/components/plField/Field';
import { Deal } from '@payaca/types/dealTypes';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';

import useGetMyAccountAccountingIntegrations from '@/api/queries/me/useGetMyAccountAccountingIntegrations';
import useGetProjectAccountingIntegrationCategories from '@/api/queries/project/useGetProjectAccountingIntegrationCategories';

import useUpdateProject from '@/api/mutations/project/useUpdateProject';
import Select, { SelectSizeVariant } from '@payaca/components/plSelect/Select';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { Controller, FormProvider, useForm } from 'react-hook-form';

export const ProjectAccountingIntegrationCategoriesControl: FC<{
  projectId: Deal['id'];
}> = ({ projectId }) => {
  const { data, isLoading } = useGetMyAccountAccountingIntegrations();

  const { accountingIntegrationCategories, refetch } =
    useGetProjectAccountingIntegrationCategories(projectId);

  const { mutateAsync: updateProjectMutation } = useUpdateProject();

  const formMethods = useForm<{
    quickbooks: Record<string, string | null>;
    xero: Record<string, string | null>;
  }>();

  useEffect(() => {
    formMethods.reset({
      quickbooks: accountingIntegrationCategories?.quickbooks?.reduce(
        (acc: Record<string, string | null>, elem) => {
          acc[elem.categoryIdentifier] = elem.optionIdentifier || null;
          return acc;
        },
        {}
      ),
      xero: accountingIntegrationCategories?.xero?.reduce(
        (acc: Record<string, string | null>, elem) => {
          acc[elem.categoryIdentifier] = elem.optionIdentifier || null;
          return acc;
        },
        {}
      ),
    });
  }, [accountingIntegrationCategories]);

  useEffect(() => {
    const { unsubscribe } = formMethods.watch((value) => {
      const qb = value.quickbooks
        ? Object.entries(value.quickbooks).map(([key, value]) => {
            return {
              categoryIdentifier: key,
              optionIdentifier: value,
            };
          })
        : [];

      const xero = value.xero
        ? Object.entries(value.xero).map(([key, value]) => {
            return {
              categoryIdentifier: key,
              optionIdentifier: value || null,
            };
          })
        : [];

      void updateProjectMutation({
        projectId: projectId.toString(),
        accountingIntegrationCategories: {
          quickbooks: qb,
          xero: xero,
        },
      }).then(() => {
        void refetch();
      });
    });
    return () => unsubscribe();
  }, [formMethods.watch]);

  if (isLoading) {
    return null;
  }

  if (!data?.quickbooks?.categories.length && !data?.xero?.categories.length) {
    return null;
  }

  return (
    <PermissionGuard
      renderIfHasPermissions={[
        AccountsPermissions.UPDATE_CONNECTION_ACCOUNTING,
      ]}
    >
      <div className="space-y-2">
        <FormProvider {...formMethods}>
          {data?.quickbooks?.categories.map((c, i) => {
            return (
              <Field.Legacy key={c.identifier}>
                <Field.Label className="">Quickbooks: {c.name}</Field.Label>
                <Controller
                  name={`quickbooks.${c.identifier}`}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        sizeVariant={SelectSizeVariant.SM}
                        options={c.options.map((o) => ({
                          value: o.identifier,
                          label: o.name,
                        }))}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </Field.Legacy>
            );
          })}
          {data?.xero?.categories.map((c, i) => {
            return (
              <Field.Legacy key={c.identifier}>
                <Field.Label>Xero: {c.name}</Field.Label>
                <Controller
                  name={`xero.${c.identifier}`}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        sizeVariant={SelectSizeVariant.SM}
                        options={c.options.map((o) => ({
                          value: o.identifier,
                          label: o.name,
                        }))}
                        value={value}
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </Field.Legacy>
            );
          })}
        </FormProvider>
      </div>
    </PermissionGuard>
  );
};
