import { FC, useMemo, useState } from 'react';

import Modal, { IProps as ModalProps } from '@payaca/components/plModal/Modal';

import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import FileInput from '@payaca/components/plInput/FileInput';
import RawInput from '@payaca/components/plInput/RawInput';
import Select, { SelectSizeVariant } from '@payaca/components/plSelect/Select';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import { Material } from '@payaca/types/materialTypes';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import useDeleteUpload from '../../../../api/mutations/file/useDeleteUpload';
import useUpdateMaterial from '../../../../api/mutations/materials/useUpdateMaterial';
import materialKeys from '../../../../api/queries/materials/keyFactory';
import useGetMaterialCategories from '../../../../api/queries/materials/useGetMaterialCategories';
import { useFileUploadContext } from '../../../../context/FileUploadContext';

export type TFormState = {
  internalId: number;
  name: string;
  description?: string | null;
  categoryId?: string | null;
  image?: {
    file: File;
    preview: string;
  };
};
const materialFormSchema = z.object({
  internalId: z.number(),
  name: z.string().min(1),
  description: z.string().nullable().optional(),
  categoryId: z.string().nullable().optional(),
  image: z
    .object({
      file: z.any(),
      preview: z.string().nullish(),
    })
    .optional(),
});
const EditMaterialModalBody: FC<Omit<Props, 'isOpen'>> = (
  props
): JSX.Element => {
  const { onPersistMaterialSuccess, material, onClose } = props;
  const { materialCategories } = useGetMaterialCategories();
  const { mutateAsync: updateMaterialMutation } = useUpdateMaterial();
  const { mutateAsync: deleteUploadMutation } = useDeleteUpload();
  const { enqueueUploads } = useFileUploadContext();
  const [isProcessing, setIsProcessing] = useState(false);

  const formMethods = useForm<TFormState>({
    resolver: zodResolver(materialFormSchema),
    defaultValues: {
      internalId: material.internalId,
      name: material.name,
      description: material.description,
      categoryId: material.category?.id
        ? material.category.id.toString()
        : null,
      image: {
        preview: material.image?.thumbnailUrl,
      },
    },
  });

  const onSubmit = async (state: TFormState) => {
    if (isProcessing) {
      return;
    }
    setIsProcessing(true);
    let imageUploadIntentId: string | undefined = undefined;
    if (material.image?.thumbnailUrl && !state.image?.preview) {
      // delete image upload from material
      await deleteUploadMutation({ uploadId: material.image.id });
    }
    if (state.image?.file) {
      // new upload
      const uploadIntents = await enqueueUploads([
        {
          file: state.image.file,
          invalidateQueryKey: materialKeys.material(material.id.toString()),
        },
      ]);
      imageUploadIntentId = uploadIntents[0]?.id;
    }
    const body = {
      internalId: state.internalId,
      name: state.name,
      description: state.description,
      categoryId: state.categoryId,
      imageUploadIntentId,
    };
    const { updateMaterial } = await updateMaterialMutation(body);
    onPersistMaterialSuccess?.(updateMaterial.id);
    setIsProcessing(false);
    onClose();
  };

  const materialCategoryOptions = useMemo(() => {
    return (
      materialCategories?.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      }) || []
    );
  }, [materialCategories]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit, console.error)}>
        <Modal.Body className="flex flex-col gap-4">
          <Field.Legacy>
            <Field.Label>Thumbnail</Field.Label>

            <Controller
              name="image"
              render={({ field: { onChange, value } }) => {
                return (
                  <FileInput
                    label={'Upload Image'}
                    url={value?.preview}
                    onDrop={(file) => {
                      onChange({
                        file,
                        preview: URL.createObjectURL(file),
                      });
                    }}
                    onDelete={() => {
                      onChange({
                        file: undefined,
                        preview: null,
                      });
                    }}
                    dropzoneOptions={{
                      accept: getAcceptedFileTypes(['image']),
                    }}
                  />
                );
              }}
            />
          </Field.Legacy>

          <Field.Legacy
            validationState={
              formMethods.formState.errors.name
                ? {
                    isValid: false,
                    validationMessages: ['Name is required'],
                  }
                : undefined
            }
          >
            <Field.Label>Material name</Field.Label>
            <RawInput {...formMethods.register('name')} />
          </Field.Legacy>

          <Field.Legacy>
            <Field.Label>Description</Field.Label>
            <Controller
              name="description"
              render={({ field: { onChange, value } }) => {
                return (
                  <Textarea
                    value={value}
                    onChange={onChange}
                    rows={1}
                    autoHeight
                  />
                );
              }}
            />
          </Field.Legacy>

          <Field.Legacy>
            <Field.Label>Category</Field.Label>
            <Controller
              name="categoryId"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    sizeVariant={SelectSizeVariant.SM}
                    value={value}
                    onChange={onChange}
                    options={materialCategoryOptions}
                  />
                );
              }}
            />
          </Field.Legacy>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Actions className="space-x-2">
            <Button onClick={onClose} variant={EBtnVariant.White}>
              Cancel
            </Button>
            <Button type="submit" isProcessing={isProcessing}>
              Save
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </form>
    </FormProvider>
  );
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onPersistMaterialSuccess?: (materialPublicId: string) => void;
  material: Pick<Material, 'name' | 'description' | 'category'> & {
    id: string;
    internalId: number;
    image?: {
      id: string;
      thumbnailUrl: string;
    };
  };
} & Pick<ModalProps, 'isOpen' | 'onClose'>;
const EditMaterialModal: FC<Props> = (props) => {
  const { isOpen, ...rest } = props;
  return (
    <Modal
      isOpen={isOpen}
      title="Edit Material"
      onClose={rest.onClose}
      size="md"
      disableBackdropClick
    >
      <EditMaterialModalBody {...rest} />
    </Modal>
  );
};

export default EditMaterialModal;
