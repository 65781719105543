import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import Field from '@payaca/components/plField/Field';
import TextareaFieldFormatter, {
  ToolbarColourVariant,
} from '@payaca/components/textareaField/TextareaFieldFormatter';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { z } from 'zod';

export type TLineItemTitleDescriptionFormState = {
  description: string;
};

export const LINE_ITEM_TITLE_DESCRIPTION_FORM_SCHEMA = z.object({
  description: z.string().min(1),
});

type TProps = {
  className?: string;
};

const TitleDescriptionField: FC<TProps> = (props) => {
  const { className } = props;

  const formMethods = useFormContext<TLineItemTitleDescriptionFormState>();

  return (
    <Field.Legacy
      className={className}
      validationState={
        formMethods.formState.errors.description
          ? {
              isValid: false,
              validationMessages: ['Title & Description is required'],
            }
          : undefined
      }
    >
      <Field.Label>Title & description</Field.Label>

      <Controller
        name="description"
        render={({ field: { onChange, onBlur, value, ref, name } }) => {
          return (
            <TextareaFieldFormatter
              styleVariant={InputStyleVariant.STANDARD}
              value={value}
              name={name}
              onChange={(newState) => {
                if (newState.description !== value) {
                  // Avoid dirtying the state when the user hasn't made any changes
                  onChange(newState.description);
                }
              }}
              toolbarColourVariant={ToolbarColourVariant.WHITE}
            />
          );
        }}
      />
    </Field.Legacy>
  );
};

export default TitleDescriptionField;
