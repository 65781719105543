import useCreateEmailTemplate from '@/api/mutations/emailTemplates/useCreateEmailTemplate';
import useUpdateEmailTemplate from '@/api/mutations/emailTemplates/useUpdateEmailTemplate';
import emailTemplateKeys from '@/api/queries/emailTemplates/keyFactor';
import useGetEmailTemplate from '@/api/queries/emailTemplates/useGetEmailTemplate';
import { EmailTemplateType } from '@/gql/graphql';
import AvailableEmailTemplateVariablesList from '@/ui/components/companySettings/components/AvailableEmailTemplateVariablesList';
import EmailTemplateUploadField from '@/ui/components/companySettings/components/EmailTemplateUploadField';
import { zodHumanFriendlyFormErrorMap } from '@/utils/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import RawInput from '@payaca/components/plInput/RawInput';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import Select from '@payaca/components/plSelect/Select';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import useDeleteEmailTemplate from '../../../../api/mutations/emailTemplates/useDeleteEmailTemplate';
import DeleteEmailTemplateModal from './DeleteEmailTemplateModal';

type AutomationEmailTemplateType = Extract<
  EmailTemplateType,
  | 'AUTOMATION_TEMPLATE_ESTIMATE'
  | 'AUTOMATION_TEMPLATE_QUOTE'
  | 'AUTOMATION_TEMPLATE_INVOICE'
  | 'AUTOMATION_TEMPLATE_EVENT'
  | 'AUTOMATION_TEMPLATE_PROJECT'
  | 'AUTOMATION_TEMPLATE_PROPOSAL'
>;

export const emailTemplateTypeReadableMap: Record<
  AutomationEmailTemplateType,
  string
> = {
  AUTOMATION_TEMPLATE_ESTIMATE: 'Automation Template (Estimate)',
  AUTOMATION_TEMPLATE_QUOTE: 'Automation Template (Quote)',
  AUTOMATION_TEMPLATE_INVOICE: 'Automation Template (Invoice)',
  AUTOMATION_TEMPLATE_EVENT: 'Automation Template (Event)',
  AUTOMATION_TEMPLATE_PROJECT: 'Automation Template (Project)',
  AUTOMATION_TEMPLATE_PROPOSAL: 'Automation Template (Proposal)',
};

export const emailTemplateTypeSelectOptions = [
  {
    label: emailTemplateTypeReadableMap.AUTOMATION_TEMPLATE_PROPOSAL,
    value: 'AUTOMATION_TEMPLATE_PROPOSAL',
  },
  {
    label: emailTemplateTypeReadableMap.AUTOMATION_TEMPLATE_INVOICE,
    value: 'AUTOMATION_TEMPLATE_INVOICE',
  },
  {
    label: emailTemplateTypeReadableMap.AUTOMATION_TEMPLATE_EVENT,
    value: 'AUTOMATION_TEMPLATE_EVENT',
  },
  {
    label: emailTemplateTypeReadableMap.AUTOMATION_TEMPLATE_PROJECT,
    value: 'AUTOMATION_TEMPLATE_PROJECT',
  },
];

type TFormState = {
  name: string;
  type: EmailTemplateType;
  template?: {
    raw: string;
    preview: string;
  };
};

const formSchema = z.object({
  name: z.string().min(1),
  type: z.string(),
  template: z.object({
    raw: z.string().min(1),
    preview: z.string().min(1),
  }),
});

const CreateEditAutomationEmailTemplateModalContent: FC<{
  isProcessing?: boolean;
  defaultValues?: TFormState;
  onSubmit: (state: TFormState) => void;
  onDelete?: () => void;
}> = (props) => {
  const { onSubmit, defaultValues, isProcessing = false, onDelete } = props;

  const [isBlockDeleteModalOpen, setIsBlockDeleteModalOpen] = useState(false);

  const isEditing = !!defaultValues;

  const formMethods = useForm<TFormState>({
    resolver: zodResolver(formSchema, {
      errorMap: zodHumanFriendlyFormErrorMap,
    }),
    defaultValues,
  });

  const templateType = formMethods.watch('type');

  return (
    <>
      <form onSubmit={formMethods.handleSubmit(onSubmit, console.error)}>
        <Modal.Body className="space-y-4">
          <Field.Legacy
            validationState={
              formMethods.formState.errors.name
                ? {
                    isValid: false,
                    validationMessages: ['Name is required'],
                  }
                : undefined
            }
          >
            <Field.Label>Name</Field.Label>

            <RawInput {...formMethods.register('name')} />
          </Field.Legacy>

          <Field.Legacy
            validationState={
              formMethods.formState.errors.type
                ? {
                    isValid: false,
                    validationMessages: ['Type is required'],
                  }
                : undefined
            }
          >
            <Field.Label>Type</Field.Label>

            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    value={value}
                    disabled={isEditing}
                    onChange={(value) => {
                      onChange(value);

                      formMethods.setValue('template', undefined);
                    }}
                    options={emailTemplateTypeSelectOptions}
                  />
                );
              }}
              name={'type'}
              control={formMethods.control}
            />
          </Field.Legacy>

          {!!templateType && (
            <Controller
              render={({ field: { onChange, value } }) => {
                return (
                  <EmailTemplateUploadField
                    validationState={
                      formMethods.formState.errors.template
                        ? {
                            isValid: false,
                            validationMessages: ['Template is required'],
                          }
                        : undefined
                    }
                    type={templateType}
                    value={value}
                    onChange={(value) => onChange(value || null)}
                  />
                );
              }}
              name={'template'}
              control={formMethods.control}
            />
          )}

          {templateType && (
            <AvailableEmailTemplateVariablesList
              emailTemplateType={templateType}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            {onDelete && (
              <Button
                disabled={isProcessing}
                isProcessing={isProcessing}
                variant={EBtnVariant.Outline}
                colour={EBtnColour.Red}
                onClick={() => setIsBlockDeleteModalOpen(true)}
              >
                Delete
              </Button>
            )}
            <Button
              disabled={isProcessing}
              isProcessing={isProcessing}
              type="submit"
            >
              {isEditing ? 'Edit' : 'Create'} automation email template
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </form>

      {onDelete && (
        <DeleteEmailTemplateModal
          isOpen={isBlockDeleteModalOpen}
          onClose={() => setIsBlockDeleteModalOpen(false)}
          onDelete={onDelete}
          bodyText="Are you sure you want to delete this email template? This action
            cannot be undone. The email template will be continued to be used in
            any existing automations."
        />
      )}
    </>
  );
};

const CreateEditAutomationEmailTemplateModal: FC<
  { emailTemplateId?: string } & Omit<IModalProps, 'title'>
> = (props) => {
  const { onClose, emailTemplateId, ...rest } = props;

  const isEditing = emailTemplateId !== 'new';

  const { pushToast } = useToastContext();

  /**
   * Queries
   */
  const queryClient = useQueryClient();
  const { data, isLoading } = useGetEmailTemplate(
    isEditing ? emailTemplateId : undefined
  );

  /**
   * Mutations
   */
  const {
    mutateAsync: createEmailTemplate,
    isLoading: isCreatingEmailTemplate,
  } = useCreateEmailTemplate();
  const {
    mutateAsync: updateEmailTemplate,
    isLoading: isUpdatingEmailTemplate,
  } = useUpdateEmailTemplate();
  const {
    mutateAsync: deleteEmailTemplateMutation,
    isLoading: isDeletingTemplate,
  } = useDeleteEmailTemplate();

  const handleSubmit = async (state: TFormState) => {
    if (!state.template) {
      return;
    }

    if (isEditing && emailTemplateId) {
      await updateEmailTemplate({
        publicId: emailTemplateId,
        name: state.name,
        template: state.template.raw,
      }).catch(() => {
        pushToast({
          variant: 'white',
          icon: 'error',
          message: 'Failed to update email template',
        });
      });

      await queryClient.invalidateQueries({
        queryKey: emailTemplateKeys.emailTemplate(emailTemplateId),
      });
    } else {
      await createEmailTemplate({
        name: state.name,
        type: state.type,
        template: state.template.raw,
      }).catch(() => {
        pushToast({
          variant: 'white',
          icon: 'error',
          message: 'Failed to create email template',
        });
      });
    }

    await queryClient.invalidateQueries({
      queryKey: emailTemplateKeys.emailTemplates(),
    });

    onClose?.();
  };

  const handleDelete = async () => {
    if (!emailTemplateId) {
      return;
    }

    try {
      await deleteEmailTemplateMutation({
        publicId: emailTemplateId,
      });
    } catch (e) {
      pushToast({
        variant: 'white',
        icon: 'error',
        message: 'Failed to delete email template',
      });
    }

    await queryClient.invalidateQueries({
      queryKey: emailTemplateKeys.emailTemplates(),
    });

    onClose?.();
  };

  return (
    <Modal
      size="lg"
      draggable={false}
      onClose={onClose}
      title={
        isEditing
          ? `Edit Automation Email Template`
          : 'Create Automation Email Template'
      }
      {...rest}
    >
      {isEditing ? (
        !data || isLoading ? (
          <Modal.Body className="space-y-4">
            <SkeletonLoader.Input />

            <SkeletonLoader.Textarea />
          </Modal.Body>
        ) : (
          <CreateEditAutomationEmailTemplateModalContent
            defaultValues={{
              name: data.emailTemplate.name,
              type: data.emailTemplate.type,
              template: {
                raw: data.emailTemplate.template,
                preview: data.emailTemplate.preview,
              },
            }}
            isProcessing={isUpdatingEmailTemplate || isDeletingTemplate}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
          />
        )
      ) : (
        <CreateEditAutomationEmailTemplateModalContent
          isProcessing={isCreatingEmailTemplate}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

export default CreateEditAutomationEmailTemplateModal;
