import { useSelector } from '@/api/state';
import { actions as usersActions } from '@/api/users';
import { useAccountUsers } from '@/utils/storeHooks';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import Modal from '@payaca/components/plModal/Modal';
import Select, { SelectOption } from '@payaca/components/plSelect/Select';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { requestGetCustomer } from '@payaca/store/customer/customerActions';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import {
  createFormInstanceForDocument,
  getDocumentPrefillPreferences,
  requestGetDocument,
  setDocumentPrefillPreferences,
} from '@payaca/store/documents/documentActions';
import { Customer } from '@payaca/types/customerTypes';
import { Deal } from '@payaca/types/dealTypes';
import { Document, FormPrefillPreferences } from '@payaca/types/documentTypes';
import { Address } from '@payaca/types/locationTypes';
import { Task } from '@payaca/types/taskTypes';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { CustomerSelect } from '../customerSelect/CustomerSelect';

type FormState = Pick<
  FormPrefillPreferences,
  'formApproverUserId' | 'customerId' | 'customerAddressId' | 'siteAddressId'
>;

export const DocumentPrefillPreferencesControl: FC<{
  documentId: Document['id'];
  contextTaskId?: Task['id'];
  onSuccess?: () => void;
}> = ({ documentId, contextTaskId, onSuccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accountUsers = useAccountUsers();

  const [initialFormState, setInitialFormState] = useState<FormState>({});
  const [customer, setCustomer] = useState<Customer>();
  const [deal, setDeal] = useState<Deal>();
  const [isProcessing, setIsProcessing] = useState(false);

  const document = useSelector(
    (state) => state.documents.documentsStore[documentId]?.entity
  );

  const onSubmit = useCallback(
    (formState: FormState, navigateToForm: boolean) => {
      setIsProcessing(true);
      dispatch(
        setDocumentPrefillPreferences.request({
          documentId,
          prefillPreferences: formState,
          callback: () => {
            if (navigateToForm) {
              // Now that form prefill preferences have been saved, navigate to the form
              // For this to be possible, a form instance must exist.
              dispatch(
                createFormInstanceForDocument.request({
                  documentId: documentId,
                  callback: (formInstancePreviewToken: string) => {
                    setIsProcessing(false);
                    history.push(
                      `/forms/${formInstancePreviewToken}/menu?dealId=${document?.dealId}&taskId=${
                        contextTaskId || ''
                      }`
                    );
                  },
                  onErrorCallback: () => {
                    setIsProcessing(false);
                  },
                })
              );
            } else {
              setIsProcessing(false);
              onSuccess?.();
            }
          },
        })
      );
    },
    [documentId, document, contextTaskId, onSuccess]
  );

  useEffect(() => {
    dispatch(requestGetDocument(documentId));
    dispatch(usersActions.getAccountUsers());
    dispatch(
      getDocumentPrefillPreferences.request({
        documentId,
        callback: (formPrefillPreferences) => {
          setInitialFormState({
            formApproverUserId: formPrefillPreferences.formApproverUserId,
            customerId: formPrefillPreferences.customerId,
            customerAddressId: formPrefillPreferences.customerAddressId,
            siteAddressId: formPrefillPreferences.siteAddressId,
          });
        },
      })
    );
  }, []);

  useEffect(() => {
    if (initialFormState.customerId) {
      dispatch(requestGetCustomer(initialFormState.customerId, setCustomer));
    }
  }, [initialFormState]);

  useEffect(() => {
    if (document?.dealId) {
      dispatch(requestGetDeal(document.dealId, setDeal));
    }
  }, [document]);

  const userAssignmentOptions = useMemo(() => {
    const options: { label: string; value: number }[] = accountUsers
      .filter((x: any) => {
        return !x.deactivatedAt || x.inviteToken;
      })
      .map((accountUser: any) => {
        return {
          label: `${accountUser.firstname} ${accountUser.lastname}`,
          value: accountUser.id,
        };
      });

    return options;
  }, [accountUsers]);

  const customerAddressOptions = useMemo(() => {
    if (!customer?.addresses) return [];
    return customer.addresses
      .map((address) => {
        return {
          label: getAddressAsString(address.address) || '',
          value: address.address.id,
        };
      })
      .filter((o) => o.label.length);
  }, [customer]);

  const siteAddressOptions = useMemo(() => {
    const o: SelectOption<Address['id']>[] = [];

    if (deal?.siteAddresses) {
      o.push(
        ...deal.siteAddresses
          .map((address) => {
            return {
              label: getAddressAsString(address.address) || '',
              value: address.address.id,
            };
          })
          .filter((o) => o.label.length)
      );
    }

    return o;
  }, [deal]);

  return (
    <div>
      <ValidatedForm
        initialFormState={initialFormState}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => {
          const [navigateToForm, setNavigateToForm] = useState(false);

          return (
            <>
              <Modal.Body className="flex flex-col gap-4">
                <p>Pre-fill the form with information</p>

                <Field.Legacy>
                  <Field.Label>Customer</Field.Label>
                  <CustomerSelect
                    disabled={!!initialFormState.customerId}
                    customerId={formState.customerId || undefined}
                    onChange={(customerId, metadata) => {
                      onFieldChange({
                        customerId,
                        customerAddressId:
                          metadata?.customer?.addresses?.[0]?.address.id,
                      });
                      setCustomer(metadata?.customer);
                    }}
                  />
                </Field.Legacy>
                {!!customerAddressOptions.length && (
                  <Field.Legacy>
                    <Field.Label>Customer Address</Field.Label>
                    <Select
                      options={customerAddressOptions}
                      value={formState.customerAddressId || undefined}
                      onChange={(value) => {
                        onFieldChange({ customerAddressId: value });
                      }}
                    />
                  </Field.Legacy>
                )}
                {!!siteAddressOptions.length && (
                  <Field.Legacy>
                    <Field.Label>Site Address</Field.Label>
                    <Select
                      options={siteAddressOptions}
                      value={formState.siteAddressId || undefined}
                      onChange={(value) => {
                        onFieldChange({ siteAddressId: value });
                      }}
                    />
                  </Field.Legacy>
                )}
                <Field.Legacy>
                  <Field.Label>To be approved by</Field.Label>
                  <Select
                    value={formState.formApproverUserId || undefined}
                    options={userAssignmentOptions}
                    multiple={false}
                    onChange={(value) => {
                      onFieldChange({ formApproverUserId: value });
                    }}
                  />
                </Field.Legacy>
              </Modal.Body>

              <Modal.Footer>
                <Modal.Footer.Actions>
                  <Button
                    disabled={!isValid}
                    isProcessing={isProcessing && navigateToForm}
                    onClick={() => {
                      setNavigateToForm(true);
                      !isProcessing && onSubmit(formState, true);
                    }}
                    variant={EBtnVariant.Outline}
                  >
                    Start form now
                  </Button>
                  <Button
                    disabled={!isValid}
                    isProcessing={isProcessing && !navigateToForm}
                    onClick={() => {
                      setNavigateToForm(false);
                      !isProcessing && onSubmit(formState, false);
                    }}
                  >
                    Save for later
                  </Button>
                </Modal.Footer.Actions>
              </Modal.Footer>
            </>
          );
        }}
      />
    </div>
  );
};
