import { FC, useCallback, useEffect, useState } from 'react';

import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import { CreateProjectTaskActionConfig } from '@payaca/types/automationTypes';

import Combobox from '@payaca/components/plCombobox/Combobox';
import Field from '@payaca/components/plField/Field';
import { SelectSizeVariant } from '@payaca/components/plSelect/Select';
import { getListedTemplatesPage } from '@payaca/store/templates/templatesActions';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import {
  GetListedTemplatesQueryParams,
  ListedTemplatesPage,
  SortBy,
} from '@payaca/types/listedTemplateTypes';
import { SortDirection } from '@payaca/types/listViewTypes';
import { useDispatch } from 'react-redux';
import { useDebounce } from 'react-use';
import './SmsNotificationAutomationActionConfigFieldset.sass';

type Props = {
  config: CreateProjectTaskActionConfig;
  onChange: (changeValue: { [key: string]: any }) => void;
  validationState?: Record<string, FieldValidationResult>;
  touchedState?: Record<string, boolean>;
  onTouch?: (fieldName: string) => void;
  showFieldValidationMessagesUnconditionally?: boolean;
};

const CreateProjectProgressionAutomationActionConfigFieldset: FC<Props> = ({
  config,
  onChange,
  validationState,
  touchedState,
  onTouch,
  showFieldValidationMessagesUnconditionally,
}) => {
  const dispatch = useDispatch();

  const [query, setQuery] = useState('');

  const [listedTemplatesPage, setListedTemplatesPage] = useState<
    ListedTemplatesPage | undefined
  >(undefined);

  const handleGetListedTemplatesPage = useCallback((query: string) => {
    const queryParams: GetListedTemplatesQueryParams = {
      pageNumber: 1,
      pageSize: 25,
      sortBy: SortBy.NAME,
      sortDirection: SortDirection.ASCENDING,
      entityTypes: ['task'],
      searchTerm: query,
    };

    dispatch(
      getListedTemplatesPage.request({
        queryParams: queryParams,
        callback: (page: ListedTemplatesPage) => {
          setListedTemplatesPage(page);
        },
      })
    );
  }, []);

  useEffect(() => {
    handleGetListedTemplatesPage('');
  }, []);

  useDebounce(
    () => {
      handleGetListedTemplatesPage(query);
    },
    1000,
    [query]
  );

  return (
    <fieldset className="deal-create-task-automation-action-config-fieldset automation-action-config-fieldset">
      <ValidatedFieldWrapper
        validationResult={validationState?.entityTemplatePublicId}
        isTouched={
          !!touchedState?.entityTemplatePublicId ||
          showFieldValidationMessagesUnconditionally
        }
      >
        <Field.Legacy>
          <Field.Label>
            From which template would you like to create the Task?
          </Field.Label>

          <Combobox
            multiple={false}
            sizeVariant={SelectSizeVariant.SM}
            options={[
              ...(listedTemplatesPage?.items || []).map((entityTemplate) => ({
                label:
                  entityTemplate.name ||
                  entityTemplate.data.name ||
                  '*** Unknown Template ***',
                value: entityTemplate.publicId,
              })),
            ]}
            value={config.entityTemplatePublicId}
            onChange={(value) => {
              onChange({ entityTemplatePublicId: value });
            }}
            query={query}
            setQuery={setQuery}
          />
        </Field.Legacy>
      </ValidatedFieldWrapper>
    </fieldset>
  );
};

export default CreateProjectProgressionAutomationActionConfigFieldset;
