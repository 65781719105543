import {
  FormValues,
  TCreateEditCustomFieldSidebarContentProps,
} from '@/ui/components/customFieldGroupControl/CreateEditCustomFieldSidebar';
import CustomFieldGroupOptionsInput from '@/ui/components/customFieldGroupControl/components/CustomFieldGroupOptionsInput';
import { registerFieldBuilder } from '@/utils/zod';
import Conditional from '@payaca/components/conditional/Conditional';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Select from '@payaca/components/plSelect/Select';
import { CustomFieldType } from '@payaca/custom-fields/types/index';
import { camelize } from '@payaca/utilities/stringUtilities';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

const CUSTOM_FIELD_TYPE_READABLE_NAME_MAP: Record<CustomFieldType, string> = {
  textarea: 'Textarea',
  duration: 'Duration',
  'multi-upload': 'Multiple file upload',
  text: 'Text',
  email: 'Email',
  select: 'Drop-down list',
  boolean: 'True/false',
  fieldset: 'Fieldset',
  number: 'Number',
  url: 'URL',
};

const TYPE_OPTIONS = [
  { value: 'text', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['text'] },
  { value: 'email', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['email'] },
  { value: 'select', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['select'] },
  { value: 'boolean', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['boolean'] },
  { value: 'number', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['number'] },
  { value: 'url', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['url'] },
];

const FIELDSET_TYPE_OPTIONS = [
  ...TYPE_OPTIONS,
  { value: 'fieldset', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['fieldset'] },
];

export type TProps = {
  isEditing: boolean;
  fieldsetChildIndex?: number;
} & Pick<TCreateEditCustomFieldSidebarContentProps, 'allCurrentIdentifiers'>;

const CreateEditCustomFieldFieldset: FC<TProps> = (props) => {
  const { isEditing, allCurrentIdentifiers, fieldsetChildIndex } = props;

  const {
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<FormValues>();

  const registerField = registerFieldBuilder(errors);

  const namePrefix =
    typeof fieldsetChildIndex === 'number'
      ? (`children.${fieldsetChildIndex}.` as `children.${number}.`)
      : '';

  const globalType = watch('type');
  const type = watch(`${namePrefix}type`);

  return (
    <>
      <Field.Legacy {...registerField(`${namePrefix}type`)}>
        <Field.Label>Type</Field.Label>

        <Controller
          render={({ field: { value, onChange } }) => {
            return (
              <Select
                disabled={isEditing}
                options={
                  typeof fieldsetChildIndex === 'number'
                    ? TYPE_OPTIONS
                    : FIELDSET_TYPE_OPTIONS
                }
                value={value}
                onChange={onChange}
              />
            );
          }}
          name={`${namePrefix}type`}
          control={control}
        />
      </Field.Legacy>

      <Controller
        render={({ field: { value, onChange } }) => {
          return (
            <>
              <Field.Legacy
                {...registerField(`${namePrefix}identification.label`)}
              >
                <Field.Label>Label</Field.Label>

                <Input
                  value={value.label}
                  onChange={(newLabel) => {
                    if (isEditing) {
                      onChange({ ...value, label: newLabel });
                      return;
                    }

                    let newIdentifier = camelize(
                      newLabel.replace(/[^a-zA-Z0-9 ]/g, '')
                    ).slice(0, 250);

                    if (allCurrentIdentifiers.includes(newIdentifier)) {
                      newIdentifier = `${newIdentifier}${uuid().slice(0, 5)}`;
                    }

                    onChange({
                      identifier: newIdentifier,
                      label: newLabel,
                    });
                  }}
                />
              </Field.Legacy>

              <Field.Legacy
                {...registerField(`${namePrefix}identification.identifier`)}
              >
                <Field.Label>Internal name</Field.Label>

                <Input
                  disabled={isEditing}
                  value={value.identifier}
                  onChange={(v) => {
                    if (allCurrentIdentifiers.includes(v)) {
                      setError(`${namePrefix}identification.identifier`, {
                        type: 'custom',
                        message: 'This identifier is already in use',
                      });
                    } else {
                      clearErrors(`${namePrefix}identification.identifier`);
                    }

                    onChange({ ...value, identifier: v });
                  }}
                />

                <Field.Helper>
                  This unique identifier will be used for integrations.
                </Field.Helper>
              </Field.Legacy>
            </>
          );
        }}
        name={`${namePrefix}identification`}
        control={control}
      />

      <Conditional condition={type === 'select'}>
        <Field.Legacy {...registerField(`${namePrefix}options`)}>
          <Field.Label>Options</Field.Label>

          <Controller
            shouldUnregister
            render={({ field: { value, onChange } }) => {
              return (
                <CustomFieldGroupOptionsInput
                  disabled={globalType === 'fieldset' && isEditing}
                  options={value}
                  onChange={onChange}
                />
              );
            }}
            name={`${namePrefix}options`}
            control={control}
          />
        </Field.Legacy>
      </Conditional>
    </>
  );
};

export default CreateEditCustomFieldFieldset;
