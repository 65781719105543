import useGetExistingCustomerContactEmails from '@/api/queries/customers/useGetExistingCustomerContactEmails';
import { AddressLookupOrInput } from '@/ui/components/addressLookupOrInput/AddressLookupOrInput';
import ConditionalWrapper from '@payaca/components/conditionalWrapper/ConditionalWrapper';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Modal from '@payaca/components/plModal/Modal';
import ValidatedForm, {
  TFieldValidators,
  TOnFormSubmit,
  TRenderFormContents,
} from '@payaca/components/validatedForm/ValidatedForm';
import {
  allowEmptyCustomerContactPhoneNumberFieldValidator,
  getCustomerContactEmailFieldValidator,
} from '@payaca/helpers/customerValidationHelper';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';
import { FC, useCallback, useMemo } from 'react';

type TFormState = {
  name: string;
  email: string;
  phone: string;
  address: {
    line1?: string;
    line2?: string;
    city?: string;
    postalCode?: string;
    country?: string;
  };
};

export interface IProps {
  isInModal?: boolean;
  onCancel?: () => void;
  onSubmit?: TOnFormSubmit<TFormState>;
  isProcessing?: boolean;
  initialFormState?: Partial<Omit<TFormState, 'address'>>;
}

const CreateCustomerControl: FC<IProps> = (props) => {
  const {
    isInModal = false,
    onCancel,
    onSubmit,
    isProcessing = false,
    initialFormState: _initialFormState,
  } = props;

  const { data: existingCustomerContactEmails, isLoading } =
    useGetExistingCustomerContactEmails();

  const initialFormState = useMemo<TFormState>(() => {
    return {
      name: _initialFormState?.name || '',
      email: _initialFormState?.email || '',
      phone: _initialFormState?.phone || '',
      address: {},
    };
  }, [_initialFormState]);

  const fieldValidators = useMemo<TFieldValidators<TFormState>>(() => {
    return {
      name: [getIsRequiredFieldValidator()],
      email: [
        getCustomerContactEmailFieldValidator({
          existingCustomerContactEmails,
        }),
      ],
      phone: [allowEmptyCustomerContactPhoneNumberFieldValidator],
    };
  }, [existingCustomerContactEmails]);

  const renderFormContents = useCallback<TRenderFormContents<TFormState>>(
    (
      isValid,
      formState,
      validationState,
      touchedState,
      onFieldChange,
      onFieldTouch
    ) => {
      return (
        <>
          <ConditionalWrapper condition={isInModal} Wrapper={Modal.Body}>
            <div className="space-y-4">
              <Field.Legacy
                name="name"
                validationState={
                  validationState?.['name']?.isValid === false &&
                  touchedState?.['name']
                    ? {
                        isValid: false,
                        validationMessages: validationState?.['name']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Customer Name</Field.Label>

                <Input
                  value={formState.name}
                  onChange={(value) => {
                    onFieldChange({ name: value });
                  }}
                  onBlur={() => onFieldTouch('name')}
                />
              </Field.Legacy>

              <Field.Legacy
                name="email"
                validationState={
                  validationState?.['email']?.isValid === false &&
                  touchedState?.['email']
                    ? {
                        isValid: false,
                        validationMessages: validationState?.['email']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Email address</Field.Label>

                <Input
                  value={formState.email}
                  onChange={(value) => {
                    onFieldChange({ email: value });
                  }}
                  onBlur={() => onFieldTouch('email')}
                />
              </Field.Legacy>

              <Field.Legacy
                name="phone"
                validationState={
                  validationState?.['phone']?.isValid === false &&
                  touchedState?.['phone']
                    ? {
                        isValid: false,
                        validationMessages: validationState?.['phone']?.errors,
                      }
                    : undefined
                }
              >
                <Field.Label>Telephone number</Field.Label>

                <Input
                  value={formState.phone}
                  onChange={(value) => {
                    onFieldChange({ phone: value });
                  }}
                  onBlur={() => onFieldTouch('phone')}
                />
              </Field.Legacy>

              <Field.Legacy name="address">
                <Field.Label>Address</Field.Label>

                <AddressLookupOrInput
                  address={{
                    ...formState.address,
                    postcode: formState.address?.postalCode,
                  }}
                  onChange={(addressType) => {
                    const change: Record<string, any> = {};

                    if (!addressType) {
                      change.address = undefined;
                    } else {
                      Object.entries(addressType).forEach(([key, value]) => {
                        if (key === 'postcode') {
                          change[`address.postalCode`] = value;
                          return;
                        }

                        change[`address.${key}`] = value;
                      });
                    }
                    onFieldChange(change);
                  }}
                />
              </Field.Legacy>
            </div>
          </ConditionalWrapper>

          <ConditionalWrapper condition={isInModal} Wrapper={Modal.Footer}>
            <ConditionalWrapper
              condition={isInModal}
              Wrapper={Modal.Footer.Actions}
            >
              <Button
                type="button"
                variant={EBtnVariant.Outline}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                isProcessing={isProcessing}
                disabled={isProcessing || !isValid}
              >
                Save
              </Button>
            </ConditionalWrapper>
          </ConditionalWrapper>
        </>
      );
    },
    [isInModal, onCancel, isProcessing]
  );

  return (
    <ValidatedForm<TFormState>
      initialFormState={initialFormState}
      fieldValidators={fieldValidators}
      renderFormContents={renderFormContents}
      onFormSubmit={onSubmit}
    />
  );
};

export default CreateCustomerControl;
