import {
  registerFieldBuilder,
  zodHumanFriendlyFormErrorMap,
} from '@/utils/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@payaca/components/plButton/Button';
import Field from '@payaca/components/plField/Field';
import RawInput from '@payaca/components/plInput/RawInput';
import Modal from '@payaca/components/plModal/Modal';
import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { roundToDecimalPlaces } from '@payaca/helpers/numberHelper';
import * as materialsListActions from '@payaca/store/materialsList/materialsListActions';
import { truncate } from 'lodash-es';
import { useDispatch } from 'react-redux';

const editSchema = z.object({
  quantityToOrder: z.number().gte(0),
});
type EditSchema = z.infer<typeof editSchema>;

export type EditMaterialListMaterialQuantity = {
  id: string;
  quantity: number;
  quantityOrdered?: number;
  material: {
    name: string;
  };
};

export const EditMaterialsListMaterialModal: FC<{
  isOpen: boolean;
  materialsListMaterial: EditMaterialListMaterialQuantity;
  onClose: () => void;
  onPersistSuccess: () => void;
}> = ({ isOpen, onClose, materialsListMaterial, onPersistSuccess }) => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const alreadyOrdered = materialsListMaterial.quantityOrdered || 0;
  const defaultToOrder = materialsListMaterial.quantity - alreadyOrdered;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<EditSchema>({
    defaultValues: { quantityToOrder: defaultToOrder },
    reValidateMode: 'onChange',
    resolver: zodResolver(editSchema, {
      errorMap: zodHumanFriendlyFormErrorMap,
    }),
    mode: 'onBlur',
  });
  const toOrder = watch('quantityToOrder');
  const difference = toOrder - defaultToOrder;

  const onSubmit = useCallback(
    (data: EditSchema) => {
      if (isProcessing) return;

      const newQuantity = data.quantityToOrder + alreadyOrdered;
      if (newQuantity === materialsListMaterial.quantity) {
        onClose();
      }
      setIsProcessing(true);
      dispatch(
        materialsListActions.requestPersistMaterialsListMaterial(
          {
            materialsListMaterialId: +materialsListMaterial.id,
            materialQuantity: newQuantity,
          },
          () => {
            setIsProcessing(false);
            onPersistSuccess();
          }
        )
      );
    },
    [isProcessing, materialsListMaterial.id, onPersistSuccess, onClose]
  );

  const registerField = registerFieldBuilder(errors);

  return (
    <Modal
      title={`Edit ${truncate(materialsListMaterial.material.name, {
        length: 40,
      })}`}
      isOpen={isOpen}
      onClose={onClose}
      size="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Field.Legacy {...registerField('quantityToOrder')}>
            <div className="flex flex-row">
              <div className="flex-1">
                <Field.Label>To order</Field.Label>
                <RawInput
                  className="max-w-24"
                  {...register('quantityToOrder', { valueAsNumber: true })}
                  type="number"
                  step="any"
                  min="0"
                />
              </div>
              <Field.Legacy className="flex-1">
                <Field.Label>Ordered</Field.Label>
                <span>{alreadyOrdered}</span>
              </Field.Legacy>
            </div>
          </Field.Legacy>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Actions>
            <SubmitButton difference={difference} isProcessing={isProcessing} />
          </Modal.Footer.Actions>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const SubmitButton: FC<{
  difference: number;
  isProcessing: boolean;
}> = ({ difference, isProcessing }) => {
  let text: string | JSX.Element;
  if (difference < 0) {
    text = (
      <>
        Remove <strong>{roundToDecimalPlaces(-difference, 2)}</strong>
      </>
    );
  } else if (difference > 0) {
    text = (
      <>
        Add <strong>{roundToDecimalPlaces(difference, 2)}</strong>
      </>
    );
  } else {
    text = 'No change';
  }

  return (
    <Button
      className="min-w-28"
      type="submit"
      isProcessing={isProcessing}
      disabled={difference === 0}
    >
      {text}
    </Button>
  );
};
