import { FC, useContext, useEffect, useMemo, useState } from 'react';

import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import ButtonRadioGroup from '@payaca/components/plButtonRadioGroup/ButtonRadioGroup';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import moment from 'moment-timezone';

import { useAccount } from '@/utils/storeHooks';
import Field, { FieldContext } from '@payaca/components/plField/Field';
import Select, { SelectSizeVariant } from '@payaca/components/plSelect/Select';
import Switch from '@payaca/components/plSwitch/Switch';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import UntitledIcon from '@payaca/untitled-icons';
import {
  ScheduleContext,
  ScheduleRangeUnit,
} from '../contextProviders/ScheduleContextProvider';
import { DatePickerPopover } from '../datePicker/DatePickerPopover';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';

export type ScheduleRange = {
  startDate: Date;
  endDate: Date;
  rangeUnit: ScheduleRangeUnit;
};

export const RangeUnitMomentMap: Record<
  ScheduleRangeUnit,
  moment.unitOfTime.StartOf
> = {
  day: 'day',
  week: 'isoWeek',
  month: 'month',
};

const ScheduleFilters: FC = () => {
  const account = useAccount();
  const calendarRef = useContext(ScheduleContext).calendarRef;
  const { startAt, endAt } = useContext(ScheduleContext).requestData;
  const scheduleRangeUnit = useContext(ScheduleContext).scheduleRangeUnit;
  const displayType = useContext(ScheduleContext).displayType;
  const onUpdate = useContext(ScheduleContext).onUpdate;
  const { calendarType } = useContext(ScheduleContext).calendarViewData;
  const [displayStartAt, setDisplayStartAt] = useState(startAt);
  const [displayEndAt, setDisplayEndAt] = useState(endAt);

  const { focusedDate, setFocusedDate } = useContext(ScheduleContext);

  const onIncrementRange = (unitIncrement: number) => {
    setFocusedDate(
      moment(focusedDate)
        .add(unitIncrement, scheduleRangeUnit)
        .endOf(RangeUnitMomentMap[scheduleRangeUnit])
        .toDate()
    );
  };

  useEffect(() => {
    const startAt = moment(focusedDate)
      .startOf(RangeUnitMomentMap[scheduleRangeUnit])
      .add(displayType === 'calendar' ? -1 : 0, scheduleRangeUnit)
      .toDate();
    const endAt = moment(focusedDate)
      .endOf(RangeUnitMomentMap[scheduleRangeUnit])
      .add(displayType === 'calendar' ? 1 : 0, scheduleRangeUnit)
      .toDate();

    setDisplayStartAt(
      moment(focusedDate)
        .startOf(RangeUnitMomentMap[scheduleRangeUnit])
        .toDate()
    );

    setDisplayEndAt(
      moment(focusedDate).endOf(RangeUnitMomentMap[scheduleRangeUnit]).toDate()
    );

    onUpdate?.({
      startAt,
      endAt,
    });

    calendarRef?.current?.getApi()?.gotoDate(focusedDate);
  }, [focusedDate, scheduleRangeUnit, displayType]);

  const viewDateLabel = useMemo(() => {
    switch (scheduleRangeUnit) {
      case 'day':
        return `${moment(displayStartAt).format(
          getInternationalMomentDateFormatByRegion(
            DateFormats.TODAY,
            account.region
          )
        )}`;
      case 'week': {
        const format = getInternationalMomentDateFormatByRegion(
          DateFormats.SHORT,
          account.region
        );
        return `${moment(displayStartAt).format(format)} - ${moment(
          displayEndAt
        ).format(format)}`;
      }
      default:
        return `${moment(displayStartAt).format('MMMM YYYY')}`;
    }
  }, [scheduleRangeUnit, displayStartAt, displayEndAt, account.region]);

  const calendarView = useMemo(() => {
    switch (calendarType) {
      case 'grid':
        switch (scheduleRangeUnit) {
          case 'day':
            return 'timeGridDay';
          case 'week':
            return 'timeGridWeek';
          case 'month':
            return 'dayGridMonth';
        }
        break;
      case 'userResource':
        switch (scheduleRangeUnit) {
          case 'day':
            return 'resourceTimelineDay';
          case 'week':
            return 'resourceTimelineWeek';
          case 'month':
            return 'resourceTimelineMonth';
        }
    }
  }, [calendarType, scheduleRangeUnit, displayType]);

  useEffect(() => {
    calendarRef?.current?.getApi()?.changeView(calendarView);
  }, [calendarView, calendarRef?.current]);

  return (
    <div className="flex w-full flex-wrap items-start gap-2">
      <div className="shrink-1 mr-auto flex grow-0 gap-2">
        <DatePickerPopover
          onChange={(date) => {
            date && setFocusedDate(date);
          }}
          defaultDate={new Date()}
        >
          {({ setIsOpen }) => (
            <Button
              variant={EBtnVariant.White}
              onClick={() => setIsOpen(true)}
              size={EBtnSize.Small}
            >
              Jump to date
            </Button>
          )}
        </DatePickerPopover>
        <Button
          variant={EBtnVariant.White}
          onClick={() => {
            setFocusedDate(new Date());
            calendarRef?.current?.getApi()?.today();
          }}
          size={EBtnSize.Small}
        >
          Today
        </Button>
      </div>
      <div className="flex items-center gap-4">
        <Button
          variant={EBtnVariant.White}
          onClick={() => {
            onIncrementRange(-1);
            calendarRef?.current?.getApi()?.prev();
          }}
          size={EBtnSize.Small}
        >
          <span className="sr-only">Previous period</span>
          <UntitledIcon name="chevron-left" className="h-5 w-5" />
        </Button>
        <span className="text-base">{viewDateLabel}</span>
        <Button
          variant={EBtnVariant.White}
          size={EBtnSize.Small}
          onClick={() => {
            onIncrementRange(1);
            calendarRef?.current?.getApi()?.next();
          }}
        >
          <span className="sr-only">Next period</span>
          <UntitledIcon name="chevron-right" className="h-5 w-5" />
        </Button>
      </div>
      <div className="shrink-1 ml-auto w-[180px] grow-0">
        <Select
          sizeVariant={SelectSizeVariant.SM}
          value={scheduleRangeUnit}
          onChange={(scheduleRangeUnit) => {
            onUpdate({
              scheduleRangeUnit: scheduleRangeUnit as ScheduleRangeUnit,
            });
          }}
          options={[
            { label: 'Day', value: 'day' },
            { label: 'Week', value: 'week' },
            { label: 'Month', value: 'month' },
          ]}
        >
          {displayType === 'calendar' && (
            <>
              <hr className="border-gray-200" />
              <ExcludeNonBusinessHoursField />
            </>
          )}
        </Select>
      </div>
      {displayType === 'calendar' && (
        <PermissionGuard
          renderIfHasPermissions={[
            ScheduledEventsPermissions.GET_EVENTS,
            ScheduledEventsPermissions.GET_MY_DEAL_EVENTS,
          ]}
        >
          <PermissionGuard
            renderIfHasPermissions={[AccountsPermissions.GET_USERS]}
          >
            <div className="shrink-0 grow-0">
              <ButtonRadioGroup
                value={calendarType}
                onChange={(calendarType) => {
                  onUpdate({ calendarType });
                }}
                options={[
                  { label: 'Grid', value: 'grid' },
                  { label: 'User', value: 'userResource' },
                ]}
                sizeVariant={EBtnSize.Small}
                CustomOption={({ option }) => {
                  return (
                    <UntitledIcon
                      className="h-6 w-6"
                      name={option.value == 'grid' ? 'calendar' : 'list'}
                    />
                  );
                }}
              />
            </div>
          </PermissionGuard>
        </PermissionGuard>
      )}
    </div>
  );
};

export default ScheduleFilters;

const ExcludeNonBusinessHoursField: FC = () => {
  const onUpdate = useContext(ScheduleContext).onUpdate;
  const { excludeNonBusinessHours } =
    useContext(ScheduleContext).calendarViewData;
  const { id } = useContext(FieldContext);

  return (
    <Field.Legacy>
      <div className="items-top flex gap-4 px-4 py-2">
        <div className="shrink-0">
          <Switch
            value={excludeNonBusinessHours}
            onChange={(value) => {
              onUpdate({
                excludeNonBusinessHours: value,
              });
            }}
          />
        </div>
        <label htmlFor={id}>Show only business hours</label>
      </div>
    </Field.Legacy>
  );
};
