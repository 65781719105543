import useUpdateTag from '@/api/mutations/tag/useUpdateTag';
import useGetMyAvailableTags from '@/api/queries/me/useGetMyAvailableTags';
import { UpdateTagInput } from '@/gql/graphql';
import Button from '@payaca/components/plButton/Button';
import { ColourInput } from '@payaca/components/plColourInput/ColourInput';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Modal from '@payaca/components/plModal/Modal';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import {
  VALID_HEX_COLOUR,
  VALID_TAG_CHARACTERS_REGEX,
} from '@payaca/constants/regexConstants';
import {
  getIsRequiredFieldValidator,
  getLengthFieldValidator,
  getRegexMatchFieldValidator,
  getValueMustNotExistInArrayFieldValidator,
} from '@payaca/helpers/fieldValidationHelper';
import { FC, useCallback, useMemo, useState } from 'react';

export const EditTagModal: FC<{
  isOpen: boolean;
  tag: UpdateTagInput;
  onClose: () => void;
  onSuccess?: () => void;
}> = ({ isOpen, tag, onClose, onSuccess }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const initialFormState = useMemo(() => {
    return tag;
  }, [isOpen, tag]);

  const { data: tags } = useGetMyAvailableTags({
    status: ['active', 'archived'],
  });

  const { existingActiveTagNames, existingArchivedTagNames } = useMemo(() => {
    if (!tags)
      return { existingActiveTagNames: [], existingArchivedTagNames: [] };

    return tags.reduce(
      (
        acc: {
          existingActiveTagNames: string[];
          existingArchivedTagNames: string[];
        },
        t
      ) => {
        if (t.id === tag.tagId) return acc;

        if (t.archivedAt) {
          acc.existingArchivedTagNames.push(t.name);
        } else {
          acc.existingActiveTagNames.push(t.name);
        }

        return acc;
      },
      {
        existingActiveTagNames: [],
        existingArchivedTagNames: [],
      }
    );
  }, [tags, tag]);

  const fieldValidators = useMemo(() => {
    return {
      name: [
        getIsRequiredFieldValidator(),
        getRegexMatchFieldValidator(VALID_TAG_CHARACTERS_REGEX, {
          customErrorMessage: 'Invalid Tag name',
        }),
        getLengthFieldValidator({ min: 1, max: 30 }),
        getValueMustNotExistInArrayFieldValidator(existingActiveTagNames, {
          customErrorMessage: 'Tag name already exists',
        }),
        getValueMustNotExistInArrayFieldValidator(existingArchivedTagNames, {
          customErrorMessage: 'An archived Tag with this name already exists',
        }),
      ],
      colour: [getRegexMatchFieldValidator(VALID_HEX_COLOUR)],
    };
  }, [existingActiveTagNames, existingArchivedTagNames]);

  const { mutateAsync: updateTagMutation, isLoading: isCreatingTag } =
    useUpdateTag();

  const onSubmit = useCallback(
    (input: UpdateTagInput) => {
      setIsProcessing(true);
      updateTagMutation(input)
        .then(() => {
          onClose();
          onSuccess?.();
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [onSuccess, updateTagMutation, onClose]
  );

  return (
    <Modal title="Edit Tag" isOpen={isOpen} onClose={onClose}>
      <ValidatedForm
        fieldValidators={fieldValidators}
        initialFormState={initialFormState}
        renderFormContents={(
          isValid,
          formState,
          validationState,
          touchedState,
          onFieldChange,
          onFieldTouch
        ) => {
          return (
            <>
              <Modal.Body>
                <div className="flex flex-col gap-4">
                  <Field.Legacy
                    name="name"
                    validationState={
                      validationState?.['name']?.isValid === false &&
                      touchedState?.['name']
                        ? {
                            isValid: false,
                            validationMessages:
                              validationState?.['name']?.errors,
                          }
                        : undefined
                    }
                  >
                    <Field.Label>Tag Name</Field.Label>
                    <Input
                      value={formState.name || ''}
                      onChange={(value) => onFieldChange({ name: value })}
                      onBlur={() => onFieldTouch('name')}
                      onChangeTimeout={() => onFieldTouch('name')}
                    />
                  </Field.Legacy>
                  <Field.Legacy
                    name="colour"
                    validationState={
                      validationState?.['colour']?.isValid === false &&
                      touchedState?.['colour']
                        ? {
                            isValid: false,
                            validationMessages:
                              validationState?.['colour']?.errors,
                          }
                        : undefined
                    }
                  >
                    <Field.Label>Colour</Field.Label>
                    <ColourInput
                      value={formState.colour || ''}
                      onChange={(value) => onFieldChange({ colour: value })}
                      onTouch={() => onFieldTouch('colour')}
                    ></ColourInput>
                  </Field.Legacy>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Modal.Footer.Actions>
                  <Button
                    isProcessing={isProcessing}
                    disabled={!isValid}
                    onClick={() => {
                      !isProcessing && onSubmit(formState);
                    }}
                  >
                    Save
                  </Button>
                </Modal.Footer.Actions>
              </Modal.Footer>
            </>
          );
        }}
      />
    </Modal>
  );
};
