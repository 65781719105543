import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@payaca/components/plButton/Button';
import Card from '@payaca/components/plCard/Card';
import Field from '@payaca/components/plField/Field';
import CheckboxGroupField from '@payaca/components/plGroupField/CheckboxGroupField';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import { FC, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Prompt } from 'react-router';
import { z } from 'zod';
import useUpdateFieldServiceSupplierIds from '../../../api/mutations/account/useUpdateFieldServiceSupplierIds';
import useGetMyAccountFieldServiceSettings from '../../../api/queries/me/useGetMyAccountFieldServiceSettings';
import useGetSuppliers from '../../../api/queries/suppliers/useGetSuppliers';

const fieldServiceSettingsSchema = z.object({
  supplierIds: z.array(z.string()),
});
type FieldServiceSettingsSchema = z.infer<typeof fieldServiceSettingsSchema>;

type Props = {
  readOnly: boolean;
};

const CompanySettingsFieldServiceSuppliers: FC<Props> = (props) => {
  const { readOnly } = props;
  const { pushToast } = useToastContext();

  const { data: fieldServiceSettings, refetch } =
    useGetMyAccountFieldServiceSettings();
  const { suppliers } = useGetSuppliers({}, { limit: 100 });
  const { mutateAsync: updateFieldServiceSupplierIdsMutation } =
    useUpdateFieldServiceSupplierIds();
  // TODO: fs suppliers - what if more than 100 suppliers?

  const formMethods = useForm<FieldServiceSettingsSchema>({
    resolver: zodResolver(fieldServiceSettingsSchema),
    defaultValues: {
      supplierIds: fieldServiceSettings?.allowedSupplierIds || [],
    },
  });
  useEffect(() => {
    if (fieldServiceSettings) {
      formMethods.reset({
        supplierIds: fieldServiceSettings?.allowedSupplierIds || [],
      });
    }
  }, [fieldServiceSettings, formMethods.reset]);

  const onSubmit = async (data: FieldServiceSettingsSchema) => {
    const response = await updateFieldServiceSupplierIdsMutation({
      allowedSupplierIds: data.supplierIds,
    });
    if (
      response.updateFieldServiceSupplierIds?.__typename ===
      'UpdateFieldServiceSupplierIdsResponseSuccess'
    ) {
      pushToast({
        variant: 'white',
        icon: 'success',
        message: 'Saved successfully',
      });
      formMethods.reset({
        supplierIds: response.updateFieldServiceSupplierIds.allowedSupplierIds,
      });
      void refetch();
    } else {
      pushToast({
        variant: 'white',
        icon: 'error',
        message: `We couldn't save your changes`,
      });
    }
  };

  return (
    <Card>
      <Card.Body className="space-y-4">
        <h2>Suppliers</h2>
        <p>
          Choose which Suppliers you can choose Materials from on the Field
          Service app.
        </p>
        {!fieldServiceSettings?.allowedSupplierIds || !suppliers ? (
          <SkeletonLoader />
        ) : (
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Controller
                name="supplierIds"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Field.Legacy name="supplierIds">
                      <CheckboxGroupField
                        horizontal
                        onChange={(v) => onChange(v.supplierIds)}
                        options={suppliers?.items.map((s) => ({
                          label: s.name,
                          value: s.id,
                        }))}
                        value={value}
                      />
                    </Field.Legacy>
                  );
                }}
              />
              <div className="flex justify-end mt-4">
                <Button
                  type="submit"
                  disabled={readOnly || !formMethods.formState.isDirty}
                >
                  Save
                </Button>
              </div>
            </form>
          </FormProvider>
        )}
      </Card.Body>
      <Prompt
        when={formMethods.formState.isDirty}
        message="There are unsaved changes on the page, are you sure you want to leave?"
      />
    </Card>
  );
};

export default CompanySettingsFieldServiceSuppliers;
