import { FC, useMemo } from 'react';

import Modal from '@payaca/components/plModal/Modal';

import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import { useFileUploadContext } from '@/context/FileUploadContext';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import CurrencyInput from '@payaca/components/plInput/CurrencyInput';
import FileInput from '@payaca/components/plInput/FileInput';
import RawInput, {
  InputSizeVariant,
} from '@payaca/components/plInput/RawInput';
import Select, { SelectSizeVariant } from '@payaca/components/plSelect/Select';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import useCreateMaterial from '../../../api/mutations/materials/useCreateMaterial';
import useGetMaterialCategories from '../../../api/queries/materials/useGetMaterialCategories';
import useGetMyTaxRates from '../../../api/queries/me/useGetMyTaxRates';
import { CreateMaterialMutation } from '../../../gql/graphql';
import SupplierSelectionControl from '../supplierSelectionControl/SupplierSelectionControl';

export type TFormState = {
  name: string;
  description?: string;
  categoryId?: string;
  image?: {
    file: File;
    preview: string;
  };
  suppliedBy: {
    supplierId: any;
    unitPriceExcTax: number;
    reference?: string;
    url?: string;
    taxRateId: string;
  }[];
};
const materialFormSchema = z.object({
  name: z.string().min(1),
  description: z.string().optional(),
  categoryId: z.string().optional(),
  image: z
    .object({
      file: z.any(),
      preview: z.string(),
    })
    .optional(),
});
const supplierMaterialFormSchema = materialFormSchema.extend({
  suppliedBy: z.array(
    z.object({
      supplierId: z.string(),
      unitPriceExcTax: z.number(),
      taxRateId: z.string(),
      url: z.string().optional(),
      reference: z.string().optional(),
    })
  ),
});

const CreateMaterialBody: FC<Omit<Props, 'isOpen'>> = (props): JSX.Element => {
  const {
    onClose,
    onPersistMaterialSuccess,
    material,
    enableSupplierMaterialInput = false,
  } = props;
  const { data: regionPreferences } = useGetMyRegionPreferences();
  const { defaultTaxRate, taxRates: allTaxRates } = useGetMyTaxRates();
  const { materialCategories } = useGetMaterialCategories();
  const { mutateAsync: createMaterialMutation } = useCreateMaterial();
  const { enqueueUploads } = useFileUploadContext();

  const taxRates = allTaxRates?.filter((t) => !t.isReverseCharge);

  const formMethods = useForm<TFormState>({
    resolver: zodResolver(
      enableSupplierMaterialInput
        ? supplierMaterialFormSchema
        : materialFormSchema
    ),
    defaultValues: {
      name: material?.name || '',
      suppliedBy: [
        {
          taxRateId: defaultTaxRate?.id,
        },
      ],
    },
  });

  const onSubmit = async (state: TFormState) => {
    let imageUploadIntentId: string | undefined = undefined;
    if (state.image?.file) {
      const uploadIntents = await enqueueUploads([{ file: state.image.file }]);

      imageUploadIntentId = uploadIntents[0]?.id;
    }

    const body = {
      name: state.name,
      description: state.description,
      categoryId: state.categoryId,
      suppliedBy: state.suppliedBy,
      imageUploadIntentId,
    };

    const { createMaterial } = await createMaterialMutation(body);

    onPersistMaterialSuccess?.(createMaterial);
    onClose();
  };

  const materialCategoryOptions = useMemo(() => {
    return (
      materialCategories?.map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      }) || []
    );
  }, [materialCategories]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Modal.Body className="flex flex-col gap-4">
          <Field.Legacy>
            <Field.Label>Thumbnail</Field.Label>

            <Controller
              name="image"
              render={({ field: { onChange, value } }) => {
                return (
                  <FileInput
                    label={'Upload Image'}
                    url={value?.preview}
                    onDrop={(file) => {
                      onChange({
                        file,
                        preview: URL.createObjectURL(file),
                      });
                    }}
                    onDelete={() => {
                      onChange({
                        file: undefined,
                      });
                    }}
                    dropzoneOptions={{
                      accept: getAcceptedFileTypes(['image']),
                    }}
                  />
                );
              }}
            />
          </Field.Legacy>

          <Field.Legacy
            validationState={
              formMethods.formState.errors.name
                ? {
                    isValid: false,
                    validationMessages: ['Name is required'],
                  }
                : undefined
            }
          >
            <Field.Label>Material name</Field.Label>
            <RawInput {...formMethods.register('name')} />
          </Field.Legacy>

          <Field.Legacy>
            <Field.Label>Description</Field.Label>
            <Controller
              name="description"
              render={({ field: { onChange, value } }) => {
                return (
                  <Textarea
                    value={value}
                    onChange={onChange}
                    rows={1}
                    autoHeight
                  />
                );
              }}
            />
          </Field.Legacy>

          <Field.Legacy>
            <Field.Label>Category</Field.Label>
            <Controller
              name="categoryId"
              render={({ field: { onChange, value } }) => {
                return (
                  <Select
                    sizeVariant={SelectSizeVariant.SM}
                    value={value}
                    onChange={onChange}
                    options={materialCategoryOptions}
                  />
                );
              }}
            />
          </Field.Legacy>

          {enableSupplierMaterialInput && (
            <>
              <Field.Legacy
                validationState={
                  formMethods.formState.errors.suppliedBy?.[0]?.supplierId
                    ? {
                        isValid: false,
                        validationMessages: ['Supplier is required'],
                      }
                    : undefined
                }
              >
                <Field.Label>Supplier</Field.Label>
                <Controller
                  name="suppliedBy[0].supplierId"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <SupplierSelectionControl
                        selectedSupplierId={value ? +value : undefined}
                        onChange={(c) => {
                          onChange(`${c}`);
                        }}
                        enableSupplierCreation
                        excludeSystemManaged
                      />
                    );
                  }}
                />
              </Field.Legacy>

              <div className="row flex gap-x-4">
                <Field.Legacy
                  validationState={
                    formMethods.formState.errors.suppliedBy?.[0]
                      ?.unitPriceExcTax
                      ? {
                          isValid: false,
                          validationMessages: ['Price is required'],
                        }
                      : undefined
                  }
                >
                  <Field.Label>Price (ex tax)</Field.Label>
                  <Controller
                    name="suppliedBy[0].unitPriceExcTax"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CurrencyInput
                          sizeVariant={InputSizeVariant.SM}
                          inputClassName="text-right"
                          value={value}
                          onChange={onChange}
                          currency={regionPreferences?.currency}
                        />
                      );
                    }}
                  />
                </Field.Legacy>
                <Field.Legacy
                  validationState={
                    formMethods.formState.errors.suppliedBy?.[0]?.taxRateId
                      ? {
                          isValid: false,
                          validationMessages: ['Tax is required'],
                        }
                      : undefined
                  }
                >
                  <Field.Label>Tax</Field.Label>
                  <Controller
                    name="suppliedBy[0].taxRateId"
                    defaultValue={
                      taxRates?.find((taxRate) => taxRate.isDefault)?.id
                    }
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        <Select
                          sizeVariant={SelectSizeVariant.SM}
                          value={value}
                          onChange={onChange}
                          options={[
                            ...(taxRates
                              ?.filter((taxRate) => !taxRate.isReverseCharge)
                              .map((taxRate) => ({
                                label: taxRate.label,
                                value: taxRate.id,
                              })) || []),
                            ...(taxRates
                              ?.filter((taxRate) => taxRate.isReverseCharge)
                              .map((taxRate) => ({
                                label: taxRate.label,
                                value: taxRate.id,
                              })) || []),
                          ]}
                        />
                      );
                    }}
                  />
                </Field.Legacy>
              </div>
              <Field.Legacy>
                <Field.Label>Material URL</Field.Label>
                <RawInput {...formMethods.register('suppliedBy.0.url')} />
              </Field.Legacy>
              <Field.Legacy>
                <Field.Label>Supplier reference</Field.Label>
                <RawInput {...formMethods.register('suppliedBy.0.reference')} />
              </Field.Legacy>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.Footer.Actions className="space-x-2">
            <Button onClick={onClose} variant={EBtnVariant.White}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </form>
    </FormProvider>
  );
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onPersistMaterialSuccess?: (
    material: CreateMaterialMutation['createMaterial']
  ) => void;
  material?: { name: string };
  enableSupplierMaterialInput?: boolean;
};
const CreateMaterialModal: FC<Props> = (props) => {
  const { isOpen, onClose, ...rest } = props;
  return (
    <Modal
      isOpen={isOpen}
      title="Create Material"
      onClose={onClose}
      size="md"
      disableBackdropClick
    >
      <CreateMaterialBody onClose={onClose} {...rest} />
    </Modal>
  );
};
export default CreateMaterialModal;
