import { SortDirection } from '@payaca/types/listViewTypes';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Combobox from '@payaca/components/plCombobox/Combobox';
import Field from '@payaca/components/plField/Field';
import { getListedTemplatesPage } from '@payaca/store/templates/templatesActions';
import {
  PublicHydratedEntityTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';
import { ListedTemplatesPage, SortBy } from '@payaca/types/listedTemplateTypes';

interface Props<TTemplatableEntity extends TemplatableEntity> {
  label?: string;
  selectedTemplate?: PublicHydratedEntityTemplate<TTemplatableEntity>;
  onChange: (
    selectedTemplate?: PublicHydratedEntityTemplate<TTemplatableEntity>
  ) => void;
  placeholder?: string;
  allowedEntityTypes?: TTemplatableEntity[];
}

const TemplateSelectionControl = <T extends TemplatableEntity>({
  label,
  selectedTemplate,
  onChange,
  placeholder,
  allowedEntityTypes,
}: Props<T>): JSX.Element => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState<string>();
  const changeTimeout = useRef<NodeJS.Timeout | null>(null);

  const [requiresGetListedTemplatesPage, setRequiresGetListedTemplatesPage] =
    useState(false);

  const [listedTemplatesPage, setListedTemplatesPage] = useState<
    ListedTemplatesPage | undefined
  >(undefined);

  const [isFetchingPage, setIsFetchingPage] = useState(false);

  useEffect(() => {
    if (changeTimeout?.current) {
      clearTimeout(changeTimeout.current);
    }
    changeTimeout.current = setTimeout(() => {
      setRequiresGetListedTemplatesPage(true);
    }, 500);
  }, [query]);

  const requestGetListedTemplatesPage = useCallback(() => {
    setIsFetchingPage(true);
    setRequiresGetListedTemplatesPage(false);

    dispatch(
      getListedTemplatesPage.request({
        queryParams: {
          sortDirection: SortDirection.DESCENDING,
          pageSize: 25,
          pageNumber: 1,
          searchTerm: query,
          sortBy: SortBy.NAME,
          entityTypes: allowedEntityTypes,
        },
        callback: (page: ListedTemplatesPage) => {
          setListedTemplatesPage(page);
          setIsFetchingPage(false);
        },
        onErrorCallback: () => {
          setIsFetchingPage(false);
        },
      })
    );
  }, [query, allowedEntityTypes]);

  useEffect(() => {
    requestGetListedTemplatesPage();
  }, []);

  useEffect(() => {
    if (requiresGetListedTemplatesPage) {
      requestGetListedTemplatesPage();
    }
  }, [requiresGetListedTemplatesPage]);

  const options =
    listedTemplatesPage?.items.map((entityTemplate) => {
      let readableEntityType = 'Entity';

      switch (entityTemplate.entityType) {
        case 'scheduledEvent':
          readableEntityType = 'Event';
          break;
        case 'task':
          readableEntityType = 'Task';
          break;
      }

      return {
        label:
          entityTemplate.name ||
          entityTemplate.data.name ||
          `${readableEntityType} Template`,
        value: entityTemplate.publicId,
        metadata: entityTemplate,
      };
    }) || [];

  return (
    <Field.Legacy name="template">
      <Field.Label>{label}</Field.Label>
      <Combobox
        options={options}
        value={selectedTemplate?.publicId}
        onChange={(value) => {
          const template = listedTemplatesPage?.items.find(
            (x) => x.publicId === value
          );
          onChange(template as PublicHydratedEntityTemplate<T>);
        }}
        placeholder={placeholder}
        query={query}
        setQuery={setQuery}
        filterFunction={() => true}
      />
    </Field.Legacy>
  );
};

export default TemplateSelectionControl;
