import { FC, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import {
  ChecklistTask,
  CustomFieldsTask,
  FormTask,
  LegacyFormTask,
  MaterialsListTask,
} from '@/gql/graphql';
import Badge from '@payaca/components/plBadge/Badge';
import UntitledIcon from '@payaca/untitled-icons';

type OwnProps = {
  to: LinkProps['to'];
};

export type TProps =
  | Pick<
      ChecklistTask,
      '__typename' | 'name' | 'completedAt' | 'checklistItems'
    >
  | Pick<FormTask, '__typename' | 'name' | 'completedAt' | 'formRevisions'>
  | Pick<
      LegacyFormTask,
      '__typename' | 'name' | 'completedAt' | 'formRevisions'
    >
  | Pick<MaterialsListTask, '__typename' | 'name' | 'completedAt'>
  | Pick<CustomFieldsTask, '__typename' | 'name' | 'completedAt'>;

const TaskCard: FC<OwnProps & TProps> = (props) => {
  const { __typename, name, completedAt, to } = props;

  const isInProgress = useMemo(() => {
    if (__typename === 'ChecklistTask') {
      const items = props.checklistItems || [];
      const itemsCompleted = items.filter((i) => !!i.completedAt);
      return !!itemsCompleted.length && items.length !== itemsCompleted.length;
    } else if (__typename === 'FormTask' || __typename === 'LegacyFormTask') {
      return !!props.formRevisions?.length;
    }
  }, [props]);

  return (
    <Link
      className="prose flex w-full cursor-pointer border-b bg-transparent py-3.5 font-normal text-blue-900 hover:no-underline"
      to={to}
    >
      <UntitledIcon
        name={__typename === 'ChecklistTask' ? 'check-done-02.3' : 'file-06.3'}
        className="mr-4 mt-1.5 h-5 w-5 flex-shrink-0 text-blue-500"
      />
      <span className="mr-1">{name}</span>
      <div className="ml-auto flex-shrink-0">
        <Badge
          colour={completedAt ? 'teal' : isInProgress ? 'yellow' : 'blue'}
          variant="soft"
          size="sm"
        >
          {completedAt ? 'Done' : isInProgress ? 'In progress' : 'To do'}
        </Badge>
      </div>
    </Link>
  );
};

export default TaskCard;
