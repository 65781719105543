import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckboxField from '@payaca/components/checkboxField/CheckboxField';
import FeedbackMessage from '@payaca/components/feedbackMessage/FeedbackMessage';
import { AlertTooltip } from '@payaca/components/iconTooltip/IconTooltip';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import {
  useMaterial,
  useMaterialPurchaseIntent,
  useMaterialsListMaterial,
  useSupplier,
} from '@payaca/store/hooks/appState';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';

type Props = {
  materialPurchaseIntentId?: number;
  supplierId: number;
  quantitySelected?: number;
  materialsListMaterialId: number;
  onUnselect: () => void;
  onSelect: (materialQuantity: number) => void;
  defaultToQuantityEditMode?: boolean;
};
const MaterialPurchaseIntentSelectionControl: FC<Props> = ({
  materialPurchaseIntentId,
  supplierId,
  quantitySelected = 0,
  materialsListMaterialId,
  onUnselect,
  onSelect,
  defaultToQuantityEditMode = false,
}: PropsWithChildren<Props>): JSX.Element => {
  const [isInQuantityEditMode, setIsInQuantityEditMode] = useState(
    defaultToQuantityEditMode
  );

  const materialsListMaterial = useMaterialsListMaterial(
    materialsListMaterialId
  );
  const materialPurchaseIntent = useMaterialPurchaseIntent(
    materialPurchaseIntentId
  );

  const material = useMaterial(materialsListMaterial?.materialId);

  const maximumQuantity = useMemo(() => {
    if (!materialsListMaterial) return 0;

    return (
      materialsListMaterial.outstandingMaterialQuantity -
      materialsListMaterial.purchaseIntendedMaterialQuantity +
      (materialPurchaseIntent?.materialQuantity || 0)
    );
  }, [materialsListMaterial, materialPurchaseIntent?.materialQuantity]);

  useEffect(() => {
    if (quantitySelected && maximumQuantity !== quantitySelected) {
      setIsInQuantityEditMode(true);
    }
  }, [maximumQuantity]);

  useEffect(() => {
    if (defaultToQuantityEditMode) {
      setIsInQuantityEditMode(true);
    }
  }, [defaultToQuantityEditMode]);

  const preferredSupplierId = useMemo(() => {
    return material?.preferredSupplierId || material?.cheapestSupplierId;
  }, [material]);

  const preferredSupplier = useSupplier(preferredSupplierId);
  const supplier = useSupplier(supplierId);

  const isAvailableAtSupplier = useMemo(() => {
    return !!material?.supplierIds.includes(supplierId);
  }, [material, supplierId]);

  const tooltipMessage = useMemo(() => {
    if (isAvailableAtSupplier) {
      if (material?.preferredSupplierId) {
        if (material.preferredSupplierId !== supplierId) {
          return `${preferredSupplier?.name} is the preferred supplier`;
        }
      } else if (
        material?.cheapestSupplierId &&
        material.cheapestSupplierId !== supplierId
      ) {
        return `${preferredSupplier?.name} is the cheapest supplier`;
      }
    } else {
      return `This material is not linked to ${supplier?.name}`;
    }
  }, [isAvailableAtSupplier, preferredSupplier?.name, material, supplierId]);

  return (
    <div
      className={`material-purchase-intent-selection-control ${
        isAvailableAtSupplier ? 'available' : ''
      }`}
    >
      {!isInQuantityEditMode && (
        <CheckboxField
          name="isSelected"
          onChange={() =>
            quantitySelected ? onUnselect() : onSelect(maximumQuantity)
          }
          value={!!quantitySelected}
          label={
            <div className="material-purchase-intent-selection-label">
              <span className="material-name">
                <span>
                  {quantitySelected ? quantitySelected : maximumQuantity}
                </span>
                <FontAwesomeIcon icon={faTimes} />
                <span>{material?.name}</span>
              </span>
              {tooltipMessage && <AlertTooltip tooltipText={tooltipMessage} />}
            </div>
          }
        />
      )}
      {isInQuantityEditMode && (
        <>
          <div className="quantity-edit-container">
            <Field.Legacy>
              <Input
                className="max-w-[65px]"
                type="number"
                value={quantitySelected.toString()}
                onChange={(value) => {
                  const quantity = Math.max(0, parseInt(value));
                  quantity ? onSelect(quantity) : onUnselect();
                }}
                min={0}
              />
            </Field.Legacy>
            <FontAwesomeIcon icon={faTimes} />
            <span>{material?.name}</span>
          </div>
          <FeedbackMessage
            feedbackLevel={FeedbackLevel.INFORMATION}
            iconBefore={faInfoCircle}
            message={`${maximumQuantity} outstanding on the materials list`}
          />
        </>
      )}
    </div>
  );
};

export default MaterialPurchaseIntentSelectionControl;
