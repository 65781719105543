import useUpdateProject from '@/api/mutations/project/useUpdateProject';
import projectKeys from '@/api/queries/project/keyFactory';
import useGetProjectOverview from '@/api/queries/project/useGetProjectOverview';
import { ProjectQuery } from '@/gql/graphql';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@payaca/components/plButton/Button';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { useSelector } from '../../../../../api/state';
import { getUserRoles } from '../../../../../utils/stateAccessors';
import {
  registerFieldBuilder,
  zodHumanFriendlyFormErrorMap,
} from '../../../../../utils/zod';

const editProjectSchema = z.object({
  reference: z.string().max(30).optional(),
  description: z.string().max(200).optional(),
});
type EditProjectSchema = z.infer<typeof editProjectSchema>;

const EditProjectModal: FC<Omit<IModalProps, 'title'>> = (props) => {
  const userRoles = useSelector(getUserRoles);
  const { ...rest } = props;
  const { dealId } = useParams<{ dealId: string }>();
  const queryClient = useQueryClient();
  const { data: projectData } = useGetProjectOverview(+dealId);
  const { mutateAsync: updateProjectMutation } = useUpdateProject({
    onMutate: async (variables) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: projectKeys.overview(+dealId),
      });

      // Snapshot the previous value
      const previousProjectOver = queryClient.getQueryData(
        projectKeys.overview(+dealId)
      );

      // Optimistically update to the new value
      queryClient.setQueryData<ProjectQuery>(
        projectKeys.overview(+dealId),
        (old) => {
          if (!old) return;

          if (!variables.customReference && !variables.description) {
            // Should never get here
            return old;
          }

          return {
            ...old,
            project: {
              ...old.project,
              reference: variables.customReference || old.project.reference,
              description:
                variables.description?.length !== 0
                  ? variables.description
                  : null,
            },
          };
        }
      );

      // Return a context object with the snapshotted value
      return { previousProjectOver };
    },
    onError: (err, newTodo, context) => {
      // If an error happens, rollback!
      queryClient.setQueryData(
        projectKeys.overview(+dealId),
        // @ts-ignore
        context.previousProjectOver
      );
    },
    // Always refetch after error or success:
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: projectKeys.project(+dealId),
      });
    },
  });

  const formMethods = useForm<EditProjectSchema>({
    resolver: zodResolver(editProjectSchema, {
      errorMap: zodHumanFriendlyFormErrorMap,
    }),
    defaultValues: {
      reference: projectData?.project.reference || undefined,
      description: projectData?.project.description || undefined,
    },
  });
  const registerField = registerFieldBuilder(formMethods.formState.errors);

  const onSubmit = async (data: EditProjectSchema) => {
    await updateProjectMutation({
      projectId: dealId,
      customReference: data.reference || null,
      description: data.description?.length === 0 ? null : data.description,
    });
    rest.onClose?.();
  };

  return (
    <Modal title="Edit Project" {...rest}>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Modal.Body className="space-y-4">
            <Field.Legacy {...registerField('reference')}>
              <Field.Label>Project reference</Field.Label>
              <Controller
                name="reference"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      value={value}
                      onChange={onChange}
                      disabled={
                        !userHasRequiredPermission(userRoles, [
                          DealsPermissions.UPDATE_DEAL_REFERENCE,
                        ])
                      }
                    />
                  );
                }}
              />
            </Field.Legacy>
            <Field.Legacy {...registerField('description')}>
              <Field.Label>Project description</Field.Label>
              <Controller
                name="description"
                render={({ field: { onChange, value } }) => {
                  return (
                    <Textarea
                      value={value}
                      onChange={onChange}
                      rows={2}
                      autoHeight
                      disabled={
                        !userHasRequiredPermission(userRoles, [
                          DealsPermissions.UPDATE_DEAL_DESCRIPTION,
                        ])
                      }
                    />
                  );
                }}
              />
            </Field.Legacy>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Footer.Actions>
              <Button type="submit">Save</Button>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditProjectModal;
